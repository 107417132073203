<template>
  <div>
    <div class="container2">
      <div class="info">
        <Subtitulo3 subTitulo3="Tu ruta de viaje es:" />
      </div>
      <div class="viaje">
        <InfoViaje textTitulo="" subTitulo4="" subTitulo5="" />
      </div>
      <div class="hora">
        <Hora />
      </div>
      <div class="pasajero">
        <Titulo Titulo="Pasajeros ida" />
      </div>
      <div v-for="(boleto, index) in boletos" :key="index + 1">
        <div class="nombre">
          <Subtitulo7 :subTitulo7="boleto.nombre_pasajero" />
        </div>
        <div class="asiento">
          <Subtitulo3
            :subTitulo3="`Asiento ${boleto.asiento} Categoría ${boleto.categoria.nombre}`"
          />
        </div>
        <div class="descuento"  v-if="boleto.descuento">
         <Subtitulo3 :subTitulo3="`Descuento ${boleto.descuento.nombre}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (max-width: 480px) {
  .container2 {
    margin-left: 6.5%;
    border-radius: 19px;
    width: 14em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -39%;
  }
  .viaje {
    text-align: left;

    margin-left: 8%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -25%;
    font-weight: bold;
  }
  .hora {
    text-align: left;
    font-size: 14px;
    margin-top: -10px;
    margin-left: 8%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -49%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -68%;
    margin-top: -20px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -13%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -28%;
    margin-top: -35px;
  }
}
@media screen and (min-width: 481px) and (max-width: 564px) {
  .container2 {
    margin-left: 15%;
    border-radius: 19px;
    width: 15em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -39%;
  }
  .viaje {
    text-align: left;
    font-family: "Montserrat";
    font-size: 16px;
    margin-left: 10%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -25%;
    font-weight: bold;
  }
  .hora {
    font-size: 14px;
    text-align: left;
    margin-top: -2%;
    margin-left: 10%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -49%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -44%;
    margin-top: -29px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -13%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -28%;
    margin-top: -35px;
  }
}
@media screen and (min-width: 565px) and (max-width: 641px) {
  .container2 {
    margin-left: 20%;
    border-radius: 19px;
    width: 15em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -39%;
  }
  .viaje {
    text-align: left;
    font-family: "Montserrat";
    font-size: 16px;
    margin-left: 10%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -25%;
    font-weight: bold;
  }
  .hora {
    text-align: left;
    font-size: 14px;
    margin-top: -4%;
    margin-left: 10%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -49%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -44%;
    margin-top: -29px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -13%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -28%;
    margin-top: -35px;
  }
}
@media screen and (min-width: 642px) and (max-width: 760px) {
  .container2 {
    margin-left: 20%;
    border-radius: 19px;
    width: 18em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -30%;
  }
  .viaje {
    text-align: left;
    font-family: "Montserrat";
    font-size: 16px;
    margin-left: 17%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -22%;
    font-weight: bold;
  }
  .hora {
    text-align: left;
    font-size: 15px;
    margin-top: -4%;
    margin-left: 17%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -40%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -36%;
    margin-top: -29px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -12%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -25%;
    margin-top: -35px;
  }
}
@media screen and (min-width: 761px) and (max-width: 1220px) {
  .container2 {
    margin-left: 30%;
    border-radius: 19px;
    width: 18em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -30%;
  }
  .viaje {
    text-align: left;
    font-family: "Montserrat";
    font-size: 16px;
    margin-left: 17%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -22%;
    font-weight: bold;
  }
  .hora {
    text-align: left;
    font-size: 14px;
    margin-top: -4%;
    margin-left: 17%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -40%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -36%;
    margin-top: -29px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -12%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -25%;
    margin-top: -35px;
  }
}
@media screen and (min-width: 1920px) {
  .container2 {
    margin-left: 39%;
    border-radius: 19px;
    width: 18em;
    height: 85%;
    background-color: rgba(248, 250, 252, 255);
  }
  .info {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
    margin-left: -30%;
  }
  .viaje {
    text-align: left;
    font-family: "Montserrat";
    font-size: 16px;
    margin-left: 17%;
    margin-top: -20px;
  }
  .viajeVuelta {
    font-size: 16px;
    margin-left: -10%;
    margin-top: -35px;
  }
  .fecha {
    font-size: 14px;
    margin-top: -30px;
    margin-left: -22%;
    font-weight: bold;
  }
  .hora {
    text-align: left;
    font-size: 14px;
    margin-top: -4%;
    margin-left: 17%;
    font-weight: bold;
  }
  .pasajero {
    font-size: 18px;
    margin-left: -40%;
  }
  .nombre {
    font-size: 14px;
    font-weight: bold;
    margin-left: -36%;
    margin-top: -29px;
  }
  .asiento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -12%;
    margin-top: -30px;
  }
  .descuento {
    font-size: 14px;
    font-weight: bold;
    margin-left: -25%;
    margin-top: -35px;
  }
}

</style>

<script>
import Stepper6 from "@/components/Stepper/Stepper6.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import Subtitulo7 from "@/components/SubTitle7.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";
import Input from "@/components/InputVue.vue";
import RutaViaje from "@/components/RutaViaje.vue";
import InfoViaje from "@/components/InfoViaje.vue";
import Hora from "@/components/SubTitle5.vue";

export default {
  name: "HomeView",
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  data: () => ({
    descuento: [],
    selectDescuento: [],
    ocultar: false,
    nombre: "",
    boletos: '',
  }),
  components: {
    InfoViaje,
    Stepper6,
    Titulo,
    Input,
    Subtitulo3,
    Back,
    AppButton,
    RutaViaje,
    Hora,
    Subtitulo7
  },
  created(){
    this.boletos = JSON.parse(this.boletos_ida);
  },
};
</script>