<template>
  <div class="container">

    
    <div class="titulo">
     
      <h1>{{ textTitulo }}</h1>
      
    </div>
    <div class="b">
       
    </div>
    <div class="ida">
      <h4>{{ subTitulo1 }}</h4>
    </div>
    <div class="vuelta">
      <h4>{{ subTitulo2 }}</h4>
    </div>
    <div class="sub4">
       <h4>{{ subTitulo4 }}</h4>
    </div>
    <div class="fecha">
      <p>{{ subTitulo3 }}</p>
    </div>
    <div class="fecha">
      <p>{{ subTitulo5 }}</p>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.titulo {
   margin-top: -2%;
  font-weight: bold;
  font-size: 20px;
}
.container {
  
  
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ida {
   font-family: "Montserrat";
  margin-top: -10%;
   
    color: #1a2b4b;

  font-weight: bold;
}
.vuelta {
   line-height: 42px;
  color: #1a2b4b;
  margin-top: -20px;
 
  font-size: 16px;
  font-weight: bold;
}
.sub4{
  line-height: 30px;
  color: #1a2b4b;
  margin-top: -20px;
 
  font-size: 16px;
  font-weight: bold;

}
.fecha {
   font-family: "Montserrat";
   line-height: 32px;
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 500;
 
    margin-top: -16px;
    
    
}
.b{
  
  margin-left: 70%;
  margin-top: 17%;
}
@media screen and (min-width: 1101px) {
  .titulo {
   margin-top: -2%;
  font-weight: bold;
  font-size: 20px;
}
.container {
  
  
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ida {
   font-family: "Montserrat";
  margin-top: -15%;
  margin-left: -1%;
    color: #1a2b4b;

  font-weight: bold;
}
.vuelta {
   line-height: 42px;
  color: #1a2b4b;
  margin-top: -20px;
 
  font-size: 16px;
  font-weight: bold;
}
.sub4{
  line-height: 30px;
  color: #1a2b4b;
  margin-top: -20px;
 
  font-size: 16px;
  font-weight: bold;

}
.fecha {
   font-family: "Montserrat";
   line-height: 32px;
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 500;
 
    margin-top: -16px;
    
    
}
.b{
  
  margin-left: 70%;
  margin-top: 17%;
}

}
</style>

<script>
// @ is an alias to /src

import Back from "@/components/Back.vue";

export default {
  props: {
    textTitulo: {
      type: String,
      default: () => "Titulo",
    },
    subTitulo1: {

      type: String,
      default: () =>  localStorage.getItem('vuelta'),
    },
    subTitulo2: {
      type: String,
      default: () => localStorage.getItem('ida'),
    },
    subTitulo3: {
      type: String,
      default: () => localStorage.getItem('fecha_ida_convertida'),
    },
    subTitulo4: {
      type: String,
      default: () => localStorage.getItem('ida'),
    },
    subTitulo5: {
      type: String,
      default: () => localStorage.getItem('fecha_vuelta_convertida'),
    },
    
  },
  name: 'HomeView',
  components: {

    Back
  }
}
</script>