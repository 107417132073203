<template>
  <div>
    <v-row>
      <div class="cuenta">
        <h1>Recuperar Contraseña</h1>
      </div>
    </v-row>
    <div>
      <input type="correo" v-model="email" placeholder="Email" />
    </div>
    <div>
      <input
        type="password"
        class="passwordnormal"
        v-model="password"
        placeholder="Crear tu contraseña"
      />
    </div>
    <div>
      <input
        type="password"
        class="passwordconfirmar"
        v-model="password_confirmation"
        placeholder="Confirmar contraseña"
      />
    </div>
    <div class="alert alert-primary" role="alert" v-if="error">
      {{ error_msg }}
    </div>
    <div>
      <button @click="recuperar()">
        <App-button v-show="!loading" :largo="true" buttonText="Guardar" />
        <div class="spinner-border spinner-border-sm"></div>
      </button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 15%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 20%;
    margin-top: 8%;
  }
  .sub {
    margin-left: -40%;
    margin-top: -5%;
    font-size: 14px;
  }
  .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 5%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 7%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordnormal {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordconfirmar {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
  }
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 30%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 10%;
    margin-top: 8%;
  }
  .sub {
    margin-left: -17%;
    margin-top: -2%;
    font-size: 14px;
  }
  .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .passwordnormal {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordconfirmar {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 40%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    margin-left: -5%;
    margin-top: 1%;
    font-size: 18px;
  }
  .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 2%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordnormal {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordconfirmar {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1201px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 41%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    margin-left: -5%;
    margin-top: 1%;
    font-size: 18px;
  }
  .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 2%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .passwordnormal {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordconfirmar {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
</style>

<script>
import axios from "axios";
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";

export default {
  name: "login",
  components: {
    Back,
    AppButton,
    Subtitulo3,
  },
  data: function () {
    return {
      name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      error: false,
      loading: false,
      error_msg: "",
      token: "",
    };
  },
  mounted() {
    localStorage.clear();
    const myKeysValue = window.location.search;
    const ulrParams = new URLSearchParams(myKeysValue);
    const tokenContraseña = ulrParams.get("token");
    localStorage.setItem("tokenContraseña", tokenContraseña);
  },
  methods: {
    async recuperar() {
      let json = {
        data: {
          type: "token",
          attributes: {
            token: localStorage.getItem("tokenContraseña"),
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
        },
      };
      this.loading = !false;
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .post("/password-reset", json, config)
        .then((response) => {
          //console.log(response);

          const { status } = response;
          let errorMessage = "";

          if (status === 204) {
            errorMessage = "Se actualizó tu contraseña correctamente";
            this.$notify({
              group: "apolo",
              type: "success",
              title: "Notificación Apolo Platinum",
              text: errorMessage,
              duration: -1
            });
            this.$router.push("/");
          } else if (status === 400) {
            errorMessage = error.response.data.errors[0].detail;
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: errorMessage,
              duration: -1
            });
          }

          setTimeout(() => {
            this.loading = true;
          }, 200);
          //this.error = true;
          //this.error_msg = errorMessage;
        })
        .catch((error) => {
          console.log(error);
          const { status, data } = error.response;
          let errorMessage = "";
          if (status === 422) {
            errorMessage = data.message;
          } else if (status === 400) {
            errorMessage = data.errors[0].detail;
          }
          setTimeout(() => {
            this.loading = false;
          }, 200);
          //this.error = true;
          //this.error_msg = errorMessage;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: errorMessage,
            duration: -1
          });
        });
      this.loading = !false;
    },
  },
};
</script>