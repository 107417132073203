<template>
  <div>
    <div class="alert alert-error" role="alert" v-if="msg">
      {{ error_msg }}
    </div>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import axios from "axios";
export default {
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  computed: {
    boletos_combinados() {
      const boletos = JSON.parse(this.boletos_ida);
      const boletos_vuelta = JSON.parse(this.boletos_vuelta);
      return boletos.concat(boletos_vuelta);
    },
  },
  data: () => ({
    monto: "",
    json_respuesta: "",
    msg: false,
    error_msg: false,
    boleto: '',
    transaccion_plataforma_id: "",
  }),
  created(){
    this.boleto = JSON.parse(this.boletos_ida);
  },
  mounted() {
        this.loadPaypal();
  },
  methods: {
    decryptData(encryptedData) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      //const secretKey = "EL_GItZZ7VqEBzLdG0nS_fOr9qWh3WvenPxskNhLTef2ImEqwoSo6-piumkJ1Rsl6E9Wz1IzTX28x5n6";
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
    async loadPaypal() {
      const sumaCifrada = localStorage.getItem("key");
      //console.log(sumaCifrada)
      const sumaDecifrada = this.decryptData(sumaCifrada);
      //console.log(sumaDecifrada)
      const suma = parseFloat(sumaDecifrada);
      //console.log(suma)
      const monto = suma;
      const fundingSources = [paypal.FUNDING.PAYPAL];
      for (const fundingSource of fundingSources) {
        const paypalButtonsComponent = paypal.Buttons({
          fundingSource: fundingSource,
          style: {
            shape: "pill",
            height: 40,
          },
          createOrder: (data, actions) => {
            const createOrderPayload = {
              purchase_units: [
                {
                  amount: {
                    value: monto,
                  },
                },
              ],
            };
            return actions.order.create(createOrderPayload);
          },
          onApprove: (data, actions) => {
            const captureOrderHandler = (details) => {
              const payerName = details.payer.name.given_name;
              localStorage.setItem("json_respuesta", JSON.stringify(details));
              const resp = JSON.parse(localStorage.getItem("json_respuesta"));
              let jsonString = JSON.stringify(resp);
              let json = {
                data: {
                  json_respuesta: jsonString,
                  monto: monto,
                },
              };
              let config = {
                headers: {
                  Accept: "application/vnd.api+json",
                  "Content-Type": "application/vnd.api+json",
                },
              };
              axios
                .post("/paypal/crearPagoPayPal", json, config)
                .then((data) => {
                  if (data.status == "200") {
                    this.transaccion_plataforma_id =
                    data.data.result.transaccion_plataforma_id;
                    this.ventaBoletos();

                    this.$router.push("/paso7");
                  }
                })
                .catch((error) => {
                  console.log(error);

                  console.log(error.response.data.errors[0].detail);
                });
            };
            return actions.order.capture().then(captureOrderHandler);
          },
          onError: (err) => {
            console.log(err);
            this.msg = true;
            this.error_msg =
              "Un error impidió que el comprador pagara con PayPal";
          },
        });
        if (paypalButtonsComponent.isEligible()) {
          paypalButtonsComponent
            .render("#paypal-button-container")
            .catch((err) => {
              console.error("PayPal Buttons failed to render");
            });
        } else {
          console.log("The funding source is ineligible");
        }
      }
    },
    async ventaBoletos() {
      const value = localStorage.getItem("value");
      if (value == 0) {
        //el viaje solo es de ida y vuelta
        this.ventaVuelta();
      } else {
        //el viaje es de ida
        this.ventaIda();
      }
    },

    async ventaIda() {
      const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      const boletos = JSON.parse(this.boletos_ida);
      if (boletos && Array.isArray(boletos)) {
        for (let i = 0; i < boletos.length; i++) {
          //console.log(boletos);
          //console.log(boletos[i].numero);
        }
      }

      const json2 = {
        data: {
          type: "venta_boleto",
          attributes: {
            token_id: localStorage.getItem("token_id"),
            corrida_ida_id: localStorage.getItem("corrida_ida_id"),
            subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
            ruta_ida_id: localStorage.getItem("ruta_ida_id"),
            fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
            es_redondo: false,
            nombre: localStorage.getItem("nombre"),
            apellido: localStorage.getItem("apellido"),
            email: localStorage.getItem("email"),
            telefono: localStorage.getItem("telefono"),
            enlinea: suma,
            plataforma: 3,
            transaccion_plataforma_id: this.transaccion_plataforma_id,
            asientos: boletos,
            corrida_vuelta_id: null,
            subcorrida_vuelta_id: null,
            ruta_vuelta_id: null,
            fecha_salida_vuelta: null,
          },
        },
      };
      const token = localStorage.getItem("access_token");
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token,
          access_token: token
        },
      };
      //console.log(json2);
      axios
        .post("/venta", json2, config)
        .then((data) => {
          if (data.status == "200") {
            localStorage.setItem("venta_exitosa", true);
            //console.log("Se hizo con exito LOS BOLETOSSS");
            //console.log(data);
          }
        })
        .catch((error) => {
          localStorage.setItem("venta_exitosa", false);
          console.log(error);
          console.log(error.response.data.errors);
        });
    },
    async ventaVuelta() {
      const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      const monto = localStorage.getItem("monto_ida");
      const boletos = JSON.parse(this.boletos_ida);
      /*
      if (boletos && Array.isArray(boletos)) {
        for (let i = 0; i < boletos.length; i++) {
          console.log(boletos);
          console.log(boletos[i].numero);
        }
      }*/

      const json2 = {
        data: {
          type: "venta_boleto",
          attributes: {
            token_id: localStorage.getItem("token_id"),
            corrida_ida_id: localStorage.getItem("corrida_ida_id"),
            subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
            ruta_ida_id: localStorage.getItem("ruta_ida_id"),
            fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
            es_redondo: true,
            nombre: localStorage.getItem("nombre"),
            apellido: localStorage.getItem("apellido"),
            email: localStorage.getItem("email"),
            telefono: localStorage.getItem("telefono"),
            enlinea: suma,
            plataforma: 3,
            transaccion_plataforma_id: this.transaccion_plataforma_id,
            asientos: this.boletos_combinados,
            corrida_vuelta_id: localStorage.getItem("corrida_vuelta_id"),
            subcorrida_vuelta_id: localStorage.getItem("subcorrida_vuelta_id"),
            ruta_vuelta_id: localStorage.getItem("ruta_vuelta_id"),
            fecha_salida_vuelta: localStorage.getItem("fecha_vuelta_hora"),
          },
        },
      };
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      //console.log(json2);
      axios
        .post("/venta", json2, config)
        .then((data) => {
          if (data.status == "200") {
            localStorage.setItem("venta_exitosa", true);
            //console.log("Se hizo con exito LOS BOLETOSSS");
            //console.log(data);
          }
        })
        .catch((error) => {
          localStorage.setItem("venta_exitosa", false);
          console.log(error);
          console.log(error.response.data.errors);
        });
    },
  },
};
</script>

<style scoped lang="less">
.alert {
  padding: 10px;
  margin-bottom: 1px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-error {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}
</style>
