<template>
  <div>
    <div class="row">
      <v-row>
        <div class="titulo2">
          <h1>Boleto a facturar</h1>
        </div>
        <div class="back">
          <router-link to="/busqueda">
            <button>
              <Back />
            </button>
          </router-link>
        </div>
      </v-row>
    </div>
    <div>
        <BoletoFactura :boleto="boleto" />
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
.titulo2 {
  font-family: "Montserrat";
  font-size: 5.8vw;
  margin-right: 20%;
  margin-left: 6%;
}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
}
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .titulo2 {
  font-family: "Montserrat";
  font-size: 24px;
  margin-right: 20%;
  margin-left: 14%;
}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
}

}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .titulo2 {
  font-family: "Montserrat";
  font-size: 28px;
  margin-right: 10%;
  margin-left: 27%;
}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
}
}
@media screen and (min-width: 1201px)  {
  .titulo2 {
  font-family: "Montserrat";
  font-size: 28px;
  margin-right: 10%;
  margin-left: 27%;
}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
}

}
</style>

<script>
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import BoletoFactura from "@/components/BoletoFactura.vue";

export default {
  name: "busqueda",
  props: {
    boleto: {required: false}
  },
  mounted() {
  },
  components: {
    Back,
    AppButton,
    BoletoFactura,
  },
};
</script>