<template>
  <div class="container">
    <div class="fecha">
      <p>{{ subTitulo3 }} </p>
    </div>
    
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.container {
  
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fecha {
      
    font-weight: 400;  
}
</style>

<script>
// @ is an alias to /src

import Back from "@/components/Back.vue";

export default {
  props: {
    
    subTitulo3: {
      type: String,
      default: () => "Sub Titulo 3",
    },
     
    
    
  },
  name: 'HomeView',
  components: {

    Back
  }
}
</script>