<template>
   <div>
       <div class="titulo">
          <Titulo Titulo="Confirma tus asientos" />
        </div>
        <div class="h5">
          <h5 align="left">
            Ya casi tienes los boletos<br />
            para tu proximo viaje, <br />
            tus asientos reservados son:
          </h5>
        </div>
        <v-col>
        <div class="ConfirmarAsiento">
          <img src="img/asientoAzul.png" />
          <div class="infoAsiento">
            <Titulo Titulo="Asiento seleccionado del layout" />
            <div class="cancelar">
              <button>
                <Cancel />
              </button>
            </div>
          </div>
        </div>
      </v-col>
        
        
   </div>
</template>

<script>
// @ is an alias to /src

import InfoViaje from "@/components/InfoViaje.vue";
import Back from "@/components/Back.vue";
import Seleccionado from "@/components/ButtonPrimary.vue";
import Stepper2 from "@/components/Stepper/Stepper2.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Toggle from "@/components/Toogle2.vue";
import Titulo from "@/components/title.vue";
import Cancel from "@/components/ButtonCancel.vue";
import Asientos from "@/components/Asientos.vue";
import Amenidades from "@/components/Amenidades.vue";
import Servicios from "@/components/Servicios.vue";
import ConfirmarAsiento from "@/components/ConfirmarAsientos.vue";

export default {
  name: "HomeView",
  components: {
    AppButton,
    InfoViaje,
    Back,
    Seleccionado,
    Stepper2,
    Toggle,
    Titulo,
    Cancel,
    Asientos,
    Amenidades,
    Servicios,
    ConfirmarAsiento
  },
  methods: {
    iniciar() {
     
    },
  },
};
</script>


<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
.titulo {
  font-family: "Montserrat";
   padding: 29px;

  
}
.ConfirmarAsiento {
  margin-top: 40px;
  margin-left: 100px;
  width: 50px;
}
.cancelar {
  margin-top: -50px;
  margin-left: -520px;
}
.infoAsiento {
  letter-spacing: 4px;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 70px;
}
.h5 {
  line-height: 22px;
  font-family: "Montserrat";
  margin-top: -15px;
  margin-left: 45px;
  color: #424242;
  font-weight: 600;

  font-size: 18px;
}
}
@media screen and (min-width: 481px) and (max-width: 800px) {
  .titulo {
   margin-left: 130px;
  font-family: "Montserrat";
   padding: 29px;

  
}
.ConfirmarAsiento {
  margin-top: 40px;
  margin-left: 60%;
  width: 50px;
}
.cancelar {
  margin-top: -50px;
  margin-left: -520px;
}
.infoAsiento {
  letter-spacing: 4px;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 70px;
}
.h5 {
  line-height: 22px;
  font-family: "Montserrat";
  margin-top: -15px;
  margin-left: 205px;
  color: #424242;
  font-weight: 600;

  font-size: 18px;
}

}
@media screen and (min-width: 801px) and (max-width: 870px) {
  .titulo {
   margin-left: 130px;
  font-family: "Montserrat";
   padding: 29px;

  
}
.ConfirmarAsiento {
  margin-top: 40px;
  margin-left: 60%;
  width: 50px;
}
.cancelar {
  margin-top: -50px;
  margin-left: -520px;
}
.infoAsiento {
  letter-spacing: 4px;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 70px;
}
.h5 {
  line-height: 22px;
  font-family: "Montserrat";
  margin-top: -15px;
  margin-left: 205px;
  color: #424242;
  font-weight: 600;

  font-size: 18px;
}

}
@media screen and (min-width: 871px) and (max-width: 1000px) {
  .titulo {
   margin-left: 270px;
  font-family: "Montserrat";
   padding: 29px;

  
}
.ConfirmarAsiento {
  margin-top: 40px;
  margin-left: 60%;
  width: 50px;
}
.cancelar {
  margin-top: -50px;
  margin-left: -520px;
}
.infoAsiento {
  letter-spacing: 4px;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 70px;
}
.h5 {
  line-height: 22px;
  font-family: "Montserrat";
  margin-top: -15px;
  margin-left: 305px;
  color: #424242;
  font-weight: 600;

  font-size: 18px;
}

}
@media screen and (min-width: 1001px)  {
  .titulo {
   margin-left: 270px;
  font-family: "Montserrat";
   padding: 29px;

  
}
.ConfirmarAsiento {
  margin-top: 40px;
  margin-left: 60%;
  width: 50px;
}
.cancelar {
  margin-top: -50px;
  margin-left: -520px;
}
.infoAsiento {
  letter-spacing: 4px;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 70px;
}
.h5 {
  line-height: 22px;
  font-family: "Montserrat";
  margin-top: -15px;
  margin-left: 305px;
  color: #424242;
  font-weight: 600;

  font-size: 18px;
}

}
</style>