import Vue from 'vue'
import VueRouter from 'vue-router'
import createWebHistory from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Paso4 from '../views/Paso4View.vue'
import Paso3 from '../views/Paso3View.vue'
import Paso2 from '../views/Paso2View.vue'
import Registro from '../views/Paso5View.vue'
import Resumen from '../views/Paso6View.vue'
import Pago from '../views/Paso7View.vue'
import BusquedaFactura from '../views/BusquedaFactura.vue'
import BoletoAFactura from '../views/BoletoAFacturar.vue'
import Login from '../views/Login.vue'
import Registrate from '../views/Registro.vue'
import Facturar from '../views/Facturar.vue'
import Cuenta from '../views/MiCuenta.vue'
import Detalle from '../views/Detallesperfil.vue'
import MisBoletos from '../views/MisBoletos.vue'
import ConfirmarFacturacion from '../views/ConfirmarFacturacion.vue'
import PagarOpen from  '../views/OpenPay.vue'
import Recibo from  '../components/Recibo.vue'
import Recuperar from  '../views/Recuperar.vue'
import DatosContacto from "@/components/DatosContactos.vue";



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/paso2',
    name: 'paso2',
    component: Paso2
  },
  {
    path: '/paso3',
    name: 'paso3',
    component: Paso3,
    props: true
  },
  {
    path: '/paso4',
    name: 'paso4',
    component: Paso4
  },
  {
    path: '/paso5',
    name: 'paso5',
    component: Registro, DatosContacto,
    props: (route) => ({ montoIda: route.query.montoIda })
  },
  {
    path: '/paso6',
    name: 'paso6',
    component: Resumen,
    props: (route) => ({ montoIda: route.query.montoIda })
  },
  {
    path: '/paso7',
    name: 'paso7',
    component: Pago
  },
  
  {
    path: '/busqueda',
    name: 'busqueda',
    component: BusquedaFactura
    
  },
  {
    path: '/boleto-factura',
    name: 'boleto-factura',
    component: BoletoAFactura,
    props: true
  },
  {
    path: '/facturar',
    name: 'facturar',
    component: Facturar
    
  },
  {
    path: '/login',
    name: 'login',
    component: Login
    
  },
  {
    path: '/registrate',
    name: 'registrate',
    component: Registrate
  },
  {
    path: '/cuenta',
    name: 'cuenta',
    component: Cuenta
    
  },
  {
    path: '/detalles',
    name: 'detalles',
    component: Detalle
    
  },
  {
    path: '/boletos',
    name: 'boletos',
    component: MisBoletos
    
  },
  {
    path: '/misdatosfacturacion',
    name: 'confirmarfacturacion',
    component: ConfirmarFacturacion
    
  },
  {
    path: '/pagar',
    name: 'pagar',
    component: PagarOpen
    
  },
  {
    path: '/recibo',
    name: 'recibo',
    component: Recibo
    
  },
  {
    path: '/recuperar',
    name: 'recuperar',
    component: Recuperar
    
  },
  
  
  {
    path: '/paso1',
    name: 'paso1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Paso1View.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  hashbang: false,
  history: true,
  root: process.env.VUE_APP_BASE_URL, 
  routes,
})

export default router
