<template>
  <div>
    <v-row>
      <div class="cuenta">
        <h1>Cuenta nueva</h1>
      </div>
      <div class="back">
        <router-link to="/login">
          <Back />
        </router-link>
      </div>
    </v-row>
    <div class="sub">
      <Subtitulo3 subTitulo3="Registra tus datos" />
    </div>
    <div>
      <input type="nombre" v-model="name" placeholder="Nombre" />
    </div>
    <div>
      <input type="apellido" v-model="last_name" placeholder="Apellidos" />
    </div>
    <div>
      <input type="correo" v-model="email" placeholder="Email" />
    </div>
    <div>
      <input
        type="contraseña"
        v-model="password"
        placeholder="Crear tu contraseña"
        class="hidden-input"
      />
    </div>
    <div>
      <input
        type="contraseñaconfirmar"
        v-model="password_confirmation"
        placeholder="Confirmar contraseña"
      />
    </div>
    <br>
    <div class="row" v-if="error">
      <div class="col-3"></div>
      <div class="alert alert-danger col-6 center" role="alert">
        {{ error_msg }}
      </div>
      <div class="col-3"></div>
    </div>
    <div>
      <button @click="registrar()">
        <App-button v-show="!loading" :largo="true" buttonText="Registrar" />
         <div class="spinner-border spinner-border-sm"></div>
      </button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 15%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 20%;
    margin-top: 8%;
  }
  .sub {
    margin-left: -40%;
    margin-top: -5%;
    font-size: 14px;
  }
   .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 5%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseñaconfirmar"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
  }
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 30%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 10%;
    margin-top: 8%;
  }
  .sub {
    margin-left: -17%;
    margin-top: -2%;
    font-size: 14px;
  }
  .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseñaconfirmar"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 40%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    margin-left: -5%;
    margin-top: 1%;
    font-size: 18px;
  }
    .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 2%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseñaconfirmar"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1201px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 41%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    margin-left: -5%;
    margin-top: 1%;
    font-size: 18px;
  }
    .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 2%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseñaconfirmar"] {
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
</style>

<script>
import axios from "axios";
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";

export default {
  name: "login",
  components: {
    Back,
    AppButton,
    Subtitulo3,
  },
  data: function () {
    return {
      name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      error: false,
      loading: false,
      error_msg: "",
    };
  },
  methods: {
  async registrar() {
      let json = {
        data: {
          type: "token",
          attributes: {
            name: this.name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
        },
      };
      this.loading=!false
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .post(
          "/register", json, config)
        .then((data) => {
          if (data.status == "200") {
            setTimeout(()=>{
              this.loading = true;
            },200),
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          if(error.response.data.errors.name != undefined){
            //this.error = true;
            //this.error_msg = error.response.data.errors.name[0];
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors.name[0],
              duration: -1
            });
            return;
          }
          if(error.response.data.errors.last_name != undefined){
            //this.error = true;
            //this.error_msg = error.response.data.errors.last_name[0];
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors.last_name[0],
              duration: -1
            });
            return;
          }
          if(error.response.data.errors.email != undefined){
            //this.error = true;
            //this.error_msg = error.response.data.errors.email[0];
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors.email[0],
              duration: -1
            });
            return;
          }
          if(error.response.data.errors.password != undefined){
            //this.error = true;
            //this.error_msg = error.response.data.errors.password[0];
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors.password[0],
              duration: -1
            });
            return;
          }
        });
        this.loading=false;
    },
  },
};
</script>