import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import Notifications from 'vue-notification'
Vue.use(Notifications)
axios.defaults.baseURL = 'https://api.apoloplatinum.com.mx/api-pasajeros/v1';

Vue.config.productionTip = false

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    //auth:{ headers: { 'Authorization': 'Bearer ' + token } },
    //authEndpoint: process.env.VUE_APP_API_BASE_URL + '/pusherAuth',
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    //wssHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    forceTLS: process.env.VUE_APP_WEBSOCKETS_FORCETLS,
    encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPTED,
    disableStats: true,
    cluster: 'us2',
    enabledTransports: ["ws", "wss"],
});

Pusher.logToConsole = true;

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
