<template>
  <div>
    <v-row>
      <p>Pasajeros</p>
      <i class="material-icons" @click="iniciar" id="button"> expand_more </i>
      <div id="overlay"></div>
      <div id="popup">
        <div class="popupcontent">
          <v-row>
            <div class="adulto">
              <p>Adulto(s)</p>
            </div>
          </v-row>
          <v-row>
            <div class="niño">
              <p>Niño(s)</p>
            </div>
          </v-row>
          <v-row>
            <div class="inapam">
              <p>INAPAM</p>
            </div>
          </v-row>
          <div class="counter1">
            <Counter />
          </div>
          
        </div>
        <div class="popupcontrols">
          <span id="popupclose">
            <div class="cerrar">
              <i class="material-icons"> close </i>
            </div>
          </span>
        </div>
        
      </div>
    </v-row>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
p {
  font-family: "Montserrat";
  font-size: 14px;
  margin-top: 5%;
  margin-left: 15%;

  font-weight: 500;
}
i {
  margin-left: 2%;
  margin-top: 6%;
}

#overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 0%;
  height: 0%;
  opacity: 0.8;
  z-index: 100;
}
#popup {
  display: none;
  position: absolute;
  top: 75%;
  left: 20%;
  background: #fff;
  width: 270px;
  height: 150px;
  margin-left: -30px; /*Half the value of width to center div*/
  margin-top: -100px; /*Half the value of height to center div*/
  z-index: 200;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}
#popupclose {
  float: right;
  padding: 10px;
  cursor: pointer;
}
.popupcontent {
  padding: 10px;
}
.adulto {
  margin-right: -10%;
  margin-left: 2%;
}
.niño {
  margin-right: -10%;
  margin-left: 3%;
}
.inapam {
  margin-right: -10%;
  margin-left: 3%;
}
.cerrar {
  margin-top: -200%;
  margin-left: -25%;
}


.counter1 {
  margin-top: -40%;
}
}
@media screen and (min-width: 481px) and (max-width: 880px) {
  p {
  font-family: "Montserrat";
  font-size: 14px;
  margin-top: 5%;
  margin-left: 15%;

  font-weight: 500;
}
i {
  margin-left: 2%;
  margin-top: 6%;
}

#overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 0%;
  height: 0%;
  opacity: 0.8;
  z-index: 100;
}
#popup {
  display: none;
  position: absolute;
  top: 689px;
  left: 20%;
  background: #fff;
  width: 270px;
  height: 150px;
  margin-left: -30px; /*Half the value of width to center div*/
  margin-top: -100px; /*Half the value of height to center div*/
  z-index: 200;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}
#popupclose {
  float: right;
  padding: 10px;
  cursor: pointer;
}
.popupcontent {
  padding: 10px;
}
.adulto {
  margin-right: -10%;
  margin-left: 2%;
}
.niño {
  margin-right: -10%;
  margin-left: 3%;
}
.inapam {
  margin-right: -10%;
  margin-left: 3%;
}
.cerrar {
  margin-top: -200%;
  margin-left: -25%;
}


.counter1 {
  margin-top: -40%;
}


}
@media screen and (min-width: 881px) and (max-width: 1280px) {
   p {
  font-family: "Montserrat";
  font-size: 16px;
  margin-top: 5%;
  margin-left: 15%;

  font-weight: 500;
}
i {
  margin-left: 2%;
  margin-top: 6%;
}

#overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 0%;
  height: 0%;
  opacity: 0.8;
  z-index: 100;
}
#popup {
  display: none;
  position: absolute;
  top: 689px;
  left: 20%;
  background: #fff;
  width: 270px;
  height: 150px;
  margin-left: -30px; /*Half the value of width to center div*/
  margin-top: -100px; /*Half the value of height to center div*/
  z-index: 200;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}
#popupclose {
  float: right;
  padding: 10px;
  cursor: pointer;
}
.popupcontent {
  padding: 10px;
}
.adulto {
  margin-right: -10%;
  margin-left: 2%;
}
.niño {
  margin-right: -10%;
  margin-left: 3%;
}
.inapam {
  margin-right: -10%;
  margin-left: 3%;
}
.cerrar {
  margin-top: -200%;
  margin-left: -25%;
}


.counter1 {
  margin-top: -40%;
}

}
@media screen and (min-width: 1281px)  {
   p {
  font-family: "Montserrat";
  font-size: 16px;
  margin-top: 5%;
  margin-left: 15%;

  font-weight: 500;
}
i {
  margin-left: 2%;
  margin-top: 6%;
}

#overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 0%;
  height: 0%;
  opacity: 0.8;
  z-index: 100;
}
#popup {
  display: none;
  position: absolute;
  top: 889px;
  left: 20%;
  background: #fff;
  width: 270px;
  height: 150px;
  margin-left: 170px; /*Half the value of width to center div*/
  margin-top: -100px; /*Half the value of height to center div*/
  z-index: 200;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}
#popupclose {
  float: right;
  padding: 10px;
  cursor: pointer;
}
.popupcontent {
  padding: 10px;
}
.adulto {
  margin-right: -10%;
  margin-left: 2%;
}
.niño {
  margin-right: -10%;
  margin-left: 3%;
}
.inapam {
  margin-right: -10%;
  margin-left: 3%;
}
.cerrar {
  margin-top: -200%;
  margin-left: -25%;
}


.counter1 {
  margin-top: -40%;
}

}
</style>

<script >
import Counter from "@/components/Counter.vue";

export default {
  name: "HomeView",
  data () {
    return {
      
			value: 0
    }
  },
  components: {
    Counter,
  },
  methods: {
    iniciar() {
      // Initialize Variables
      var closePopup = document.getElementById("popupclose");
      var overlay = document.getElementById("overlay");
      var popup = document.getElementById("popup");
      var button = document.getElementById("button");
      // Close Popup Event
      closePopup.onclick = function () {
        overlay.style.display = "none";
        popup.style.display = "none";
      };
      // Show Overlay and Popup
      button.onclick = function () {
        overlay.style.display = "block";
        popup.style.display = "block";
      };
    },
  },
};
</script>