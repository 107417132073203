import { render, staticRenderFns } from "./Backleft.vue?vue&type=template&id=3fc54b68&scoped=true&"
var script = {}
import style0 from "./Backleft.vue?vue&type=style&index=0&id=3fc54b68&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc54b68",
  null
  
)

export default component.exports