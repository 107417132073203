<template>
  <div>
    <div class="row">
      <v-row>
        <div class="titulo2">
          <h1>Mis Boletos</h1>
        </div>
        <div class="back">
          <router-link to="/cuenta">
            <button>
              <Back />
            </button>
          </router-link>
        </div>
      </v-row>
      <div class="sub">
          <h2>¿Estamos listos para viajar?</h2>
          <br>
      </div>
    </div>
    <div>
        <BoletoFactura />
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
.titulo2 {
  font-family: "Montserrat";
  font-size: 5.8vw;
  margin-right: 20%;
  margin-left: 6%;
}
.sub {
    font-family: "Montserrat";
  font-size: 3.8vw;
  margin-right: 20%;
  margin-left: 8%;
  font-weight: 500;
  margin-top: 3%;

}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
  margin-left: 15%;
}
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .titulo2 {
  font-family: "Montserrat";
  font-size: 26px;
  margin-right: 10%;
  margin-left: 26%;
}
.sub {
    font-family: "Montserrat";
  font-size: 20px;
  margin-right: 20%;
  margin-left: 28%;
  font-weight: 500;
  margin-top: 3%;

}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
  margin-left: 15%;
}

}
@media screen and (min-width: 767px) and (max-width: 939px) {
  .titulo2 {
  font-family: "Montserrat";
  font-size: 26px;
  margin-right: 10%;
  margin-left: 26%;
}
.sub {
    font-family: "Montserrat";
  font-size: 20px;
  margin-right: 20%;
  margin-left: 28%;
  font-weight: 500;
  margin-top: 3%;

}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
  margin-left: 15%;
}

}
@media screen and (min-width: 940px){
  .titulo2 {
  font-family: "Montserrat";
  font-size: 26px;
  margin-left: 20%;
}
.sub {
    font-family: "Montserrat";
  font-size: 20px;
  margin-right: 55%;
  font-weight: 500;
  margin-top: 8%;

}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -2%;
  margin-left: 5%;
}

}
</style>

<script>
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import BoletoFactura from "@/components/BoletoFactura.vue";

export default {
  name: "busqueda",
  components: {
    Back,
    AppButton,
    BoletoFactura,
  },
};
</script>