<template>
  <div>
    <br>
    <v-app>
    <v-main>
      <v-container>
        <!-- contenido de la aplicación -->
        <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <v-select
          class=""
            v-if="regimenes != null"
            v-model="factura.regimen_fiscal"
            label="Seleccione Régimen Fiscal"
            :items="regimenes"
            item-text="regimen_fiscal"
            item-value="clave"
            return-object
          ></v-select>
        </div>
        <div class="col-3"></div>
      </div>
      </v-container>
    </v-main>

    <input v-model="factura.rfc" type="Rfc" placeholder="RFC" />
    <input v-model="factura.razon_social" type="razon" placeholder="Razón social" />
    <input v-model="factura.cp" type="razon" placeholder="Código Postal" />
    <input v-model="factura.email" type="correo" placeholder="Correo a dónde enviar la factura" />
    <div class="opcionales">
      <h5>Opcionales</h5>
    </div>
    <input v-model="factura.email_adicional" type="correo" placeholder="Correo adicional dónde enviar la factura" />
    <input v-model="factura.calle" type="calle" placeholder="Calle" />
    <input v-model="factura.numero" type="numero" placeholder="Número" />
    <input v-model="factura.colonia" type="colonia" placeholder="Colonia" />
    <input v-model="factura.estado" type="estado" placeholder="Estado" />
    <input v-model="factura.ciudad" type="ciudad" placeholder="Ciudad" />
    <v-row>
      <button @click="saveDataFactura()">
        <App-button :largo="true" class="buton" buttonText="Guardar" />
      </button>
    </v-row>
      </v-app>
    
  </div>
</template>

<script>
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      token_access: null,
      dias_facturar_despues_salida: null,
      boleto_id: null,
      fecha_facturado: null,
      factura: {
        type: "facturación",
        regimen_fiscal: null,
        regimen_fiscal_key: null,
        regimen_fiscal_id: null,
        rfc: null,
        razon_social: null,
        cp: null,
        email: null,
        email_adicional: null,
        calle: null,
        numero: null,
        colonia: null,
        estado: null,
        ciudad: null,
        boleto_id: null
      },
      regimenes: null,
      cliente: null,
    };
  },
  components: {
    Titulo,
    Subtitulo3,
    AppButton,
  },
  mounted() {
    this.token_access = localStorage.getItem("access_token");
    if(this.token_access != null){
      this.getRegimenes(); 
    }
  },

  methods: {
    async saveDataFactura(){
      if(this.factura.regimen_fiscal_key === null){
          this.factura.regimen_fiscal_key = this.factura.regimen_fiscal.clave;
          this.factura.regimen_fiscal_id = this.factura.regimen_fiscal.id;
      }
      const options = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            Authorization: "Bearer " + this.token_access,
            access_token: this.token_access,
          }
        };

        axios
          .post(`/facturacion/datosFacturacion`, this.factura, options).then(response => {
            this.$notify({
              group: 'apolo',
              type: "success",
              title: 'Notificación Apolo Platinum',
              text: "Datos de facturación guardados satisfactoriamente"
            });
            this.getClienteAuth();
          });
    },
    
    async getClienteAuth(){
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        }
      };
        axios
        .get(`/facturacion/recuperarClienteAuth`, options)
        .then(response => {
          this.cliente = response.data.data;
          this.factura.regimen_fiscal = this.regimenes.filter(item => item.id == this.cliente.regimen_fiscal_id);
          this.factura.regimen_fiscal = this.factura.regimen_fiscal[0];
          this.factura.regimen_fiscal_key = this.factura.regimen_fiscal.clave;
          this.factura.regimen_fiscal_id = this.factura.regimen_fiscal.id;
          this.factura.rfc = this.cliente.rfc;
          this.factura.razon_social = this.cliente.razon_social;
          this.factura.cp = this.cliente.cp;
          this.factura.email = this.cliente.email;
          this.factura.email_adicional = this.cliente.email_adicional;
          this.factura.calle = this.cliente.calle;
          this.factura.numero = this.cliente.numero;
          this.factura.colonia = this.cliente.colonia;
          this.factura.estado = this.cliente.estado;
          this.factura.ciudad = this.cliente.ciudad;
        })
       .catch (error => {
          console.log(error.response.data.error)
       }); 
    },

    async getRegimenes() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + this.token_access,
          access_token: this.token_access,
        }
      };

      axios
        .get(`/facturacion/regimenesFiscales`, options)
        .then(response => {
          this.regimenes = response.data.data;
          this.getClienteAuth();
        });
    },
  },
};
</script>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {

input {
  margin-top: 4%;

  margin-left: 5%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 35px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
}
.opcionales{
  margin-top: 4%;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  margin-left: -52%;
}
.buton{
  margin-left: 25%;
}


}
@media screen and (min-width: 481px) and (max-width: 653px) {

input {
  margin-top: 5%;

  margin-left: 15%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 40px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}
.opcionales{
  margin-top: 4%;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  margin-left: -32%;
}
.buton{
  margin-left: 52%;
}

}
@media screen and (min-width: 654px) and (max-width: 854px) {

input {
  margin-top: 5%;
  margin-left: 40%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 40px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}
.opcionales{
  margin-top: 4%;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  margin-left: -2%;
}
.buton{
  margin-left: 250px;
}

}
@media screen and (min-width: 855px) and (max-width: 1243px) {
 
input {
  margin-top: 5%;

  margin-left: 30%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}
.opcionales{
  margin-top: 4%;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 600;
  margin-left: -2%;
}
.buton{
 margin-left: 170%;
}


}

@media screen and (min-width: 1244px)  {

input {
  margin-top: 5%;

  margin-left: 40%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}
.opcionales{
  margin-top: 4%;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 600;
  margin-left: -2%;
}
.buton{
  margin-left: 450px;
}

}
</style>