<template>
  <div>
    <div class="container">
      <button  @click="iniciar()" id="button" >Ver todas las amenidades</button>
    </div>
    

    <div id="overlay"></div>
    <div id="popup">
      <div class="popupcontrols">
        <span id="popupclose">
          <div class="cerrar">
            <div class="popupcontent">
              
              <v-row>
                <div class="servicios2">
                  <img src="img/wifit.png" />
                </div>
                <div class="wifit">
                  <h1>wi-fi</h1>
                </div>
              </v-row>
              <v-row>
                <div class="servicios3">
                  <img src="img/tv.png" />
                </div>
                <div class="tv">
                  <h1>Tv individual</h1>
                </div>
              </v-row>
              <v-row>
                <div class="servicios4">
                  <img src="img/audio.png" />
                </div>
                <div class="audio">
                  <h1>Audio Individual</h1>
                </div>
              </v-row>
              <v-row>
                <div class="servicios5">
                  <img src="img/sonido.png" />
                </div>
                <div class="sonido">
                  <h1>Sonido</h1>
                </div>
              </v-row>
              <v-row>
                <div class="servicios6">
                  <img src="img/baños.png" />
                </div>
                <div class="wc">
                  <h1>Baños Mixtos</h1>
                </div>
              </v-row>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>


<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 190px;
    margin-top: 80px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 60%;
    left: 52%;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}

@media screen and (min-width: 481px) and (max-width: 800px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 190%;
    margin-top: 160px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 51%;
    left: 380px;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}
@media screen and (min-width: 801px) and (max-width: 870px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 430px;
    margin-top: 100px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 59%;
    left: 450px;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}

@media screen and (min-width: 871px) and (max-width: 1030px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 300%;
    margin-top: 100px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 45%;
    left: 590px;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}
@media screen and (min-width: 1031px) and (max-width: 1280px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 300%;
    margin-top: 100px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 45%;
    left: 590px;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}

@media screen and (min-width: 1281px) {
  .container button {
    height: 40px;
    width: 190px;
    margin-left: 400%;
    margin-top: 100px;

    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
    cursor: pointer;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    height: 880px;
    width: 500px;
    background-color: rgba(248, 250, 252, 255);
  }
  .container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 0%;
    height: 0%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 59%;
    left: 51%;
    background: #fff;
    width: 210px;
    height: 200px;
    margin-left: -30px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .servicios2 {
    width: 100%;
    margin-left: -30%;
  }
  .wifit {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -68%;
    font-weight: 500;
  }
  .servicios3 {
    width: 50%;
    margin-left: -35%;
  }
  .tv {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -12%;
    font-weight: 500;
  }
  .servicios4 {
    width: 90%;
    margin-left: -25%;
  }
  .audio {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -0%;
    font-weight: 500;
    margin-top: -20%;
  }
  .servicios5 {
    width: 50%;
    margin-left: -30%;
  }
  .sonido {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
  }
  .servicios6 {
    width: 50%;
    margin-left: -30%;
  }
  .wc {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -22%;
    font-weight: 500;
    margin-top: -2%;
  }
}
</style>


<script>
import InfoViaje from "@/components/InfoViaje.vue";
import Back from "@/components/Back.vue";
import Seleccionado from "@/components/ButtonPrimary.vue";
import Stepper2 from "@/components/Stepper/Stepper2.vue";
import AppButton from "@/components/ButtonPrimary.vue";

import Titulo from "@/components/title.vue";
import Cancel from "@/components/ButtonCancel.vue";
import Asientos from "@/components/Asientos.vue";

export default {
  name: "HomeView",
  components: {
    AppButton,
    InfoViaje,
    Back,
    Seleccionado,
    Stepper2,
    Titulo,
    Cancel,
    Asientos,
  },
  methods: {
    iniciar() {
      // Initialize Variables
      var closePopup = document.getElementById("popupclose");
      var overlay = document.getElementById("overlay");
      var popup = document.getElementById("popup");
      var button = document.getElementById("button");
      var isOpen = false; // keep track of whether popup is open or closed
      
      // Close Popup Event
      closePopup.onclick = function () {
        overlay.style.display = "none";
        popup.style.display = "none";
        isOpen = false;
      };
      
      // Show Overlay and Popup, or close if already open
      button.onclick = function () {
        if (isOpen) {
          //console.log("log1");
          // popup is already open, so close it
          overlay.style.display = "none";
          popup.style.display = "none";
          isOpen = false;
        } else {
          // popup is closed, so open it
          //console.log("log2");
          overlay.style.display = "block";
          popup.style.display = "block";
          isOpen = true;
        }
      };
    }
  },
};
</script>