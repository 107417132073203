<template>
  <div class="card">
    <v-col>
      <Stepper3 />
    </v-col>
    <v-row>
      <div class="title">
        <InfoViaje
          textTitulo="Vuelta"
          subTitulo1=""
          subTitulo3=""
          hora=""
          horavuelta=""
        />
      </div>
      <div class="back">
        <router-link to="/paso2">
          <button>
            <Back />
          </button>
        </router-link>
      </div>
    </v-row>
    <div class="viaje">
      <ViajeVuelta />
    </div>
  </div>
</template>

<style scoped lang="less">
@media screen and (max-width: 600px) {
  .viaje {
    height: 700px;
  }
  .title {
    margin-top: 10%;
    margin-left: 6%;
    text-align: left;
  }

  .back {
    margin-left: 78%;
    margin-top: -22%;
  }
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  .title {
    margin-top: 10%;
    margin-left: 6%;
    text-align: left;
  }
  .viaje {
    margin-top: 35%;
  }
  .back {
    margin-left: 20%;
    margin-top: 9%;
  }
}
@media screen and (min-width: 801px) and (max-width: 1100px) {
  .title {
    margin-top: -1%;
    margin-left: 10%;
    text-align: left;
  }
  .viaje {
    margin-top: 20%;
  }
  .back {
    margin-left: -140px;
    margin-top: -2%;
  }
}
@media screen and (min-width: 1101px) {
  .title {
    margin-top: -1%;
    margin-left: 10%;

    text-align: left;
  }
  .viaje {
    margin-top: 15%;
  }
  .back {
    margin-left: -140px;
    margin-top: -2%;
  }
}
</style>

<script>
// @ is an alias to /src
import InfoViaje from "@/components/InfoViaje.vue";
import Back from "@/components/Back.vue";
import Seleccionado from "@/components/ButtonPrimary.vue";
import Stepper3 from "@/components/Stepper/Stepper3.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import ViajeVuelta from "@/components/ViajeVuelta.vue";

export default {
  name: "HomeView",
  components: {
    AppButton,
    InfoViaje,
    Back,
    Seleccionado,
    Stepper3,
    ViajeVuelta,
  },
  mounted() {
    const value = localStorage.getItem("value");
    /*
    if (value == 1) {
      //El viaje es sencillo
      this.$router.push("/paso2");
    } else {
      //El viaje es redondo
      this.$router.push("/paso3");
    }
    */
  },
};
</script>