<template>
  <div class="datosContactos">
    <div class="contacto">
      <Titulo Titulo="Datos de contacto" />
    </div>
    <div class="info">
      <Subtitulo3 subTitulo3="Los boletos se enviarán al:" />
    </div>
    <div>
      <input v-model="telefono" type="Telefono"  readonly/>
    </div>
    <div>
      <input v-model="email" type="email" readonly />
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .contacto {
    font-size: 18px;
    margin-top: 20px;
    margin-left: -30%;
  }
  .info {
    font-size: 14px;
    margin-top: -5%;
    margin-left: -39%;
  }
  input[type="Telefono"] {
    margin-top: 0px;
    margin-right: 19%;
    margin-left: 5%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 5%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .contacto {
    font-size: 18px;
    margin-top: 20px;
    margin-left: -8%;
  }
  .info {
    font-size: 18px;
    margin-top: -4%;
    margin-left: -0%;
  }
  input[type="Telefono"] {
    margin-top: 0px;
    margin-right: 19%;
    margin-left: 20%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 20%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1033px) {
  .contacto {
    font-size: 20px;
    margin-top: 5%;
    margin-left: -1%;
  }
  .info {
    font-size: 18px;
    margin-top: -3%;
    margin-left: 2%;
  }
  input[type="Telefono"] {
    margin-top: 0px;
    margin-right: 2%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: -9%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
}

@media screen and (min-width: 1034px) and (max-width: 1280px) {
  .contacto {
    font-size: 20px;
    margin-top: 4%;
    margin-left: -12%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: -6%;
  }
  input[type="Telefono"] {
    margin-top: 0px;
    margin-right: 12%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
}
@media screen and (min-width: 1920px) {
  .contacto {
    font-size: 20px;
    margin-top: 4%;
    margin-left: 0%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: 1%;
  }
  input[type="Telefono"] {
    margin-top: 0px;
    margin-right: 0%;
    margin-left: 0%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 0%;
    text-indent: 30px;
    margin-left: 0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
}
</style>

<script>
import Stepper5 from "@/components/Stepper/Stepper5.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import InfoPasajero from "@/components/InfoPasajero.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";
import DatosContacto from "@/components/DatosContactos.vue";
export default {
  name: "HomeView",
  components: {
    Stepper5,
    Titulo,
    Subtitulo3,
    Back,
    AppButton,
    InfoPasajero,
    DatosContacto,
  },
  data: function () {
    return {
      telefono: "",
      email: "",
    };
  },
  created() {
    let storedtelefono = localStorage.getItem("telefono");
    this.telefono = storedtelefono;
    let storedEmail = localStorage.getItem("email");
    this.email = storedEmail;
  },
   methods: {
     saveValue() {
       localStorage.setItem("email", this.email);
       localStorage.setItem("telefono", this.telefono);
       this.$router.push("/paso6");
     }
   }
};
</script>