<template>
  <div>
    <v-row>
      <div class="cuenta">
        <h1>Detalles del perfil</h1>
      </div>
      <div class="back">
        <router-link to="/cuenta">
          <Back />
        </router-link>
      </div>
    </v-row>
    <div class="sub">
      <Subtitulo3
        subTitulo3="Registra tus datos o modificalos para que coicidan con los datos de tu identificacion oficial"
      />
    </div>
    <input type="nombre" v-model="name" placeholder="Nombre" />
    <div>
      <input type="apellido" v-model="last_name" placeholder="Apellido" />
    </div>

    <div class="modificar">
      <Subtitulo3 subTitulo3="Modificar contraseña:" />
    </div>
    <input type="password" class="passwordnormal" v-model="password" placeholder="Contraseña" />
    <div>
      <input
        type="password"
        class="confirmpassword"
        v-model="password_confirmation"
        placeholder="Confirmar contraseña"
      />
    </div>
    <div>
      <button>
        <router-link to="/misdatosfacturacion">
          <App-button :largo="true" buttonText="Cambiar mis datos de facturación" />
        </router-link>
      </button>
      <div class="alert alert-primary" role="alert" v-if="error">
      {{ error_msg }}
    </div>
    </div>
    <div>
      <button @click="guardar()">
        <App-button v-show="!loading" :largo="true" buttonText="Guardar" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 15%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 10%;
    margin-top: 8%;
  }
  
  .sub {
    text-align: left;
    line-height: 22px;
    margin-left: 10%;
    margin-top: 2%;
    font-size: 14px;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 5%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .passwordnormal {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .confirmpassword {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
  }
  .modificar {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    margin-left: -34%;
    margin-top: 5%;
  }
   .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .cuenta {
    margin-top: 10%;
    margin-left: 30%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .back {
    margin-left: 10%;
    margin-top: 8%;
  }
  .sub {
    text-align: left;
    line-height: 22px;
    margin-left: 20%;
    margin-top: 2%;
    font-size: 16px;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 5%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .passwordnormal {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .confirmpassword {
    margin-top: 4%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
  .modificar {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
    margin-left: -16%;
    margin-top: 5%;
  }
   .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 40%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    text-align: left;
    line-height: 22px;
    margin-left: 30%;
    margin-top: 2%;
    font-size: 24px;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 5%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
 .passwordnormal {
    margin-top: 1%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.confirmpassword {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
   .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
}
@media screen and (min-width: 1201px) {
  .cuenta {
    margin-top: 5%;
    margin-left: 41%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .back {
    margin-left: 7%;
    margin-top: 4%;
  }
  .sub {
    margin-left: -5%;
    margin-top: 1%;
    font-size: 18px;
  }
  input[type="nombre"] {
    margin-top: -10%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="apellido"] {
    margin-top: 2%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="correo"] {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
.passwordnormal {
    margin-top: -3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
   .confirmpassword {
    margin-top: 3%;

    margin-left: 1%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 45px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
   .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
}
</style>

<script>
import axios from "axios";
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";

export default {
  name: "login",
  components: {
    Back,
    AppButton,
    Subtitulo3,
  },
  data: function () {
    return {
      name: localStorage.name,
      last_name: localStorage.last_name,
      password: "",
      password_confirmation: "",
      error: false,
      error_msg: "",
      loading: false,
    };
  },
  methods: {
    guardar() {
      const token3 = localStorage.getItem("access_token");
      let json = {
        data: {
          attributes: {
        name: this.name,
        last_name: this.last_name,
        password: this.password,
        password_confirmation: this.password_confirmation,
          }
        }
      };
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization:'Bearer ' + token3,
          'access_token': token3 
        },
      };
      this.loading = !false;
      axios
        .post(
          "/actualizarPerfil",
           json, config
        )
        .then((response) => {
          setTimeout(() => {
              this.loading = true;
            }, 200),
          //this.error = true;
          //this.error_msg = response.data.message;
          this.$notify({
            group: "apolo",
            type: "success",
            title: "Notificación Apolo Platinum",
            text: response.data.message,
            duration: -1
          });
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
          }, 200),
          //this.error = true;
          //this.error_msg = error.response.data.errors[0].detail;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.errors[0].detail,
            duration: -1
          });
          
        });
        this.loading = !false;
    },
  },
};
</script>