<template>
  <div class="container">
    <div class="titulo">
      <h1>Total es: ${{ Number(total.toFixed(2)) }} MXN</h1>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

import Back from "@/components/Back.vue";
import CryptoJS from 'crypto-js';

export default {
  data: () => ({
    total: 0,
    codigoEjecutado: false,
  }),
  mounted() {
    Promise.all([ this.value(),]);
  },

  props: {
    Monto: {
      type: String,
      default: () => localStorage.getItem("monto_ida"),
    },
  },
  name: "HomeView",

  components: {
    Back,
  },
  methods: {

   decryptData(encryptedData) {
    const secretKey = 'my-secret-key'; // clave secreta para el cifrado
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return decryptedData.toString(CryptoJS.enc.Utf8);
  },
  value() {
    //SumaCifrada = ida
    //SumaCrifada2 = vuelta
    const value = localStorage.getItem("value");
  
    const sumaCifrada = localStorage.getItem('key');
    const sumaDecifrada = this.decryptData(sumaCifrada);
    const suma = parseFloat(sumaDecifrada);
    this.total = suma;
        this.codigoEjecutado = true;
      

    
  },
   encryptData(data) {
  const secretKey = 'my-secret-key'; // clave secreta para el cifrado
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
  return encryptedData.toString();
},


  },
};
</script>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.titulo {
  color: #1a2b4b;
  font-weight: 500;
  font-size: 20px;
}
.container {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ida {
  color: #1a2b4b;
  margin-left: -0px;
  font-size: 15px;
  font-weight: bold;
}
.vuelta {
  color: #1a2b4b;
  margin-top: -16px;
  margin-left: -35px;
  font-size: 15px;
  font-weight: bold;
}
.fecha {
  color: #7c7c7c;
  font-size: 13px;
  margin-left: -90px;
  margin-top: -16px;
}
</style>
