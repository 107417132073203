<template>
  <div class="container">
    <div class="titulo">
      <h2>{{ Titulo }} </h2>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.titulo {
  font-weight: 700;
  padding-left: 20px;
}
.container {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ida {
    color: #1a2b4b;
  margin-left: -0px;
  font-size: 15px;
  font-weight: bold;
}
.vuelta {
  color: #1a2b4b;
  margin-top: -16px;
  margin-left: -35px;
  font-size: 15px;
  font-weight: bold;
}
.fecha {
    color: #7c7c7c;
    font-size: 13px;
    margin-left: -90px;
    margin-top: -16px;
    
    
}
</style>

<script>
// @ is an alias to /src

import Back from "@/components/Back.vue";

export default {
  props: {
    Titulo: {
      type: String,
      default: () => "Titulo",
    },
        
  },
  name: 'HomeView',
  components: {

    Back
  }
}
</script>