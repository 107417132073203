<template>
  <div>
    <br>
    <div class="imagen">
      <img src="img/asientos.jpg" />
      <v-btn
      style="background-color: #F7FAFC; margin-left: -50px;">
        P=Primera | C=Confort | E=Ejecutiva
      </v-btn> 
    </div>
    <v-row>
        <!--<Amenidades />-->
        <div class="col-2 text-right"></div>
        <div class="text-left col-12 align-elements" data-app>
          <img class="servicios" src="img/servicios.jpg" />
          <v-btn
            id="menu-activator"
            color="primary"
          >
            Ver todas las amenidades
          </v-btn>
          <v-menu activator="#menu-activator" :location="location">
            <v-list>
              <v-list-item
                v-for="(item, index) in atributos"
                :key="index"
                :value="index"
              >
                <v-list-item-title class="align-elements">
                  <img v-if="item.id === 1" width="10%" height="10%" src="img/amenidades/Wifi.png" />
                  <img v-if="item.id === 2" width="10%" height="10%" src="img/amenidades/tv-6'.png" />
                  <img v-if="item.id === 3" width="10%" height="10%" src="img/amenidades/pantalla-individual-8'.png" />
                  <img v-if="item.id === 4" width="10%" height="10%" src="img/amenidades/Pantallas-fijas-compartidas.png" />
                  <img v-if="item.id === 5" width="10%" height="10%" src="img/amenidades/usb.png" />
                  <img v-if="item.id === 6" width="10%" height="10%" src="img/amenidades/Conector-de-110v.png" />
                  <img v-if="item.id === 7" width="10%" height="10%" src="img/amenidades/Wc-individuales.png" />
                  <img v-if="item.id === 8" width="10%" height="10%" src="img/amenidades/wc.png" />
                  <img v-if="item.id === 9" width="10%" height="10%" src="img/amenidades/a-C.png" />
                  <img v-if="item.id === 10" width="10%" height="10%" src="img/amenidades/Asiento-tipo-cama.png" />
                  <img v-if="item.id === 11" width="10%" height="10%" src="img/amenidades/Descansaa-pies.png" />
                  <img v-if="item.id === 12" width="10%" height="10%" src="img/amenidades/Descansa-piernas.png" />
                  <img v-if="item.id === 13" width="10%" height="10%" src="img/amenidades/Cafeteria.png" />
                  <img v-if="item.id === 14" width="10%" height="10%" src="img/amenidades/Luz-individual.png" />
                  <img v-if="item.id === 15" width="10%" height="10%" src="img/amenidades/Cinturon-de-seguridad.png" />
                  <img v-if="item.id === 16" width="10%" height="10%" src="img/amenidades/confort.png" />
                  <img v-if="item.id === 17" width="10%" height="10%" src="img/amenidades/Primera.png" />
                  <img v-if="item.id === 18" width="10%" height="10%" src="img/amenidades/Ejecutiva.png" />
                  <img v-if="item.id === 19" width="10%" height="10%" src="img/amenidades/dOBLE-PISO.png" />
                  <span style="padding-top: 5px !important; padding-left: 10px !important;">{{ item.nombre }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-row>
  </div>
</template>

<style scoped lang="less">
#menu-activator{
    margin-top: 20px;
    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    text-transform: none !important;
    box-shadow: -0.5px 0.5px 0.5px 0.5px rgba(208, 215, 230, 255);
    font-size: 12px;
    background-color: #0f68a1;
    color: rgb(255, 255, 255);
    border: 1px solid #0f68a1;
    border-radius: 10px;
}
.align-elements{
  //margin-left: 25%;
  display:flex;
  justify-content: left;
  align-items:left;
}
@media screen and (max-width: 480px) {
.imagen {
  width: 320px;
  margin-left: 13.5%;
  margin-top: 30%;
}
.servicios {
  margin-left: 0.5%;
  width: 37%;
  margin-top: 1%;
}
}
@media screen and (min-width: 481px) and (max-width: 800px) {
  .imagen {
  width: 340px;
  margin-left: 25%;
  margin-top: 30%;
}
.servicios {
  margin-left: 15%;
  margin-top: 0%;
  width: 30%;
}

}
@media screen and (min-width: 801px) and (max-width: 870px) {
   .imagen {
  width: 340px;
  margin-left: 25%;
  margin-top: 20%;
}
.servicios {
  margin-left: 15%;
  margin-top: 0%;
  width: 30%;
}

}
@media screen and (min-width: 871px) and (max-width: 1030px) {
   .imagen {
  width: 340px;
  margin-left: 25%;
  margin-top: 20%;
}
.servicios {
  margin-left: 15%;
  margin-top: 0%;
  width: 30%;
}

}
@media screen and (min-width: 1031px)  {
   .imagen {
  width: 340px;
  margin-left: 31%;
  margin-top: 12%;
}
.servicios {
  margin-left:24%;
  margin-top: 0px !important;
}

}
@media screen and (min-width: 1366px)  {
   .imagen {
    width: 340px;
    margin-left: 39%;
    margin-top: 12%;
  }
  .servicios {
    margin-left: 32%;
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 1920px)  {
   .imagen {
    width: 340px;
    margin-left: 41.2%;
    margin-top: 8%;
  }
  .servicios {
    margin-left: 36%;
    margin-top: 0px !important;
  }
}

</style>

<script>
import Amenidades from "@/components/Amenidades.vue";

export default {
  name: "HomeView",
  props: {
    venta: {required: false}
  },
  data() {
    return {
      location: 'bottom',
      atributos: null
    };
  },
  components: {
    Amenidades,
  },
  created(){
    if(this.venta != undefined){
      this.atributos = this.venta.atributos.filter(item => item.status == true);
    }
  },
  methods: {
    iniciar() {
     
    },
  },
};
</script>