<template>
  <div class="app">
    <div class="stepper">
      <v-col>
        <Stepper7 />
      </v-col>
    </div>
    <v-row>
      <div class="titulo">
        <Titulo Titulo="Proceso de pago" />
      </div>
      <!--
      <div class="atras">
        <router-link to="/paso6">
          <button>
            <Back />
          </button>
        </router-link>
      </div>
      -->
      
    </v-row>

    <div>
      <OpenPay :boletos_ida="boletos_ida" :boletos_vuelta="boletos_vuelta" />
    </div>
  </div>
</template>

<script>
import OpenPay from "@/components/OpenPaymentPay.vue";
import Stepper7 from "@/components/Stepper/Stepper7.vue";
import Back from "@/components/Back.vue";
import Titulo from "@/components/title.vue";
export default {
  name: "App",
  components: {
    OpenPay,
    Stepper7,
    Back,
    Titulo,
  },
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  data() {
    return {
      //
    };
  },
};
</script>


<style scoped lang="less">
@media screen and (max-width: 650px) {
.stepper {
  margin-top: -0%;
  margin-bottom: 5%;
}
.titulo {
  margin-top: 3%;
  font-size: 26px;
  margin-left: 7%;
}
.atras {
  margin-top: 4%;
  margin-left: 7%;

}
}
@media screen and (min-width: 651px) and (max-width: 760px) {
  .stepper {
  margin-top: -0%;
  margin-bottom: 5%;
}
.titulo {
  margin-top: 3%;
  font-size: 26px;
  margin-left: 27%;
}
.atras {
  margin-top: 4%;
  margin-left: 7%;

}


}
@media screen and (min-width: 761px) and (max-width: 2000px) {
  .stepper {
  margin-top: -0%;
  margin-bottom: 5%;
}
.titulo {
  margin-top: -8%;
  font-size: 26px;
  margin-left: 4%;
}
.atras {
  margin-top: -7%;
  margin-left: 2%;

}

}
</style>