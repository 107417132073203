
<template>
  <div id="app">
    <notifications group="apolo" />
    <Navbar2 v-if="!iframe" :logueado="logueado"/>
    <div class="bg-white-100 text-2xl py-4 px-190 text-center items-center">
    <nav>
    </nav>
    <router-view 
      @login_realizado="checkLogin" 
      @eventBoletosIda="boletosIda" 
      @eventBoletosVuelta="boletosVuelta" 
      :boletos_ida="boletos_ida"
      :boletos_vuelta="boletos_vuelta"
       />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Navbar2 from '@/components/Nav.vue'


export default {
  
  name: 'HomeView',
  
  components: {
    Navbar2,
  },
  data: () => ({
    logueado: false,
    iframe: false,
    boletos_ida: null,
    boletos_vuelta: null
  }),
  mounted(){
    this.iframe = this.inIframe();
  },  
  methods: {
    checkLogin(data) {
      this.logueado = data;
    },
    boletosIda(data){
      this.boletos_ida = data;
    },
    boletosVuelta(data){
      this.boletos_vuelta = data;
    },
    inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
}
}

</script>

<style lang="less">
#app {
 
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
}

nav {
  
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #f9f9f9;
    }
  }
}
</style>



