<template>
  <div>
    <Stepper7 class="active" Text1="Paso1" />
       <div class="alert alert-primary" role="alert" v-if="msg">
        {{ msg_info }}
      </div>
      <div class="Facturar">
        <button @click="eliminarData();">
         
            <App-button :ancho="true" buttonText="Ir a la página de Inicio" />

        </button>
      </div>


  </div>

</template>
<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (max-width: 665px) {
.alert {
  margin-top: 30%;
  padding: 5px;
  margin-bottom: 10px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  margin-left: 4%;
  margin-right: 4%;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
}

@media screen and (min-width: 666px) {
.alert {
  margin-top: 15%;
  padding: 20px;
  margin-bottom: 10px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  margin-left: 20%;
  margin-right: 20%;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
}
</style>

<script>
import Stepper7 from "@/components/Stepper/Stepper7.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";

export default {
  name: "HomeView",
    data: () => ({
       msg: false,
     msg_info: "",
    }),
  components: {
    Stepper7,
    Titulo,
    Subtitulo3,
    Back,
    AppButton,
  },
   methods: {
    eliminarData() {
     this.$router.push('/') 
    }

   },
   mounted() {
     const json_respuesta = localStorage.getItem("json_respuesta");
     if( json_respuesta == null) {
       this.$router.push("/");
     } else {
       this.msg = true;
       this.msg_info = "Tu pago fue exitoso, tus boletos fueron enviados a tu correo."
       //console.log("Tienes dato del json_respuesta");
     }
   },

   
};
</script>