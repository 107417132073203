
<template>
  <div>
    <div class="login">
      <h1>Login</h1>
    </div>
    <div>
      <input
        type="correo"
        id="email"
        v-model="email"
        placeholder="Correo Electronico"
      />
    </div>

    <input
      type="contraseña"
      id="password"
      placeholder="Contraseña"
      v-model="password"
      class="hidden-input"
    />
    <div v-on:submit.prevent="login" class="button">
      <button @click="login()">
        <App-button
          v-show="!loading"
          type="submit"
          :largo="true"
          buttonText="Iniciar sesión"
        />
        <div class="spinner-border spinner-border-sm"></div>
      </button>
    </div>
    <div class="alert alert-danger" role="alert" v-if="error">
      {{ error_msg }}
    </div>
    <div class="alert alert-success" role="alert" v-else-if="valido">
      {{ error_msg }}
    </div>
    <div class="registrate">
      <router-link to="registrate">
        <h5>Regístrate</h5>
      </router-link>
    </div>
    <div class="recuperar">
      <router-link id="button" to="">
        <h5 v-show="!loading" @click="iniciar">Recuperar Contraseña</h5>
      </router-link>
    </div>
    <div id="overlay"></div>
    <div id="popup">
      <div class="popupcontent">
        <div class="titulo">
          <h1>Correo electrónico de recuperación</h1>
        </div>
      </div>
      <div class="correo2">
        <input
          type="correo2"
          v-model="recuperar"
          placeholder="Correo electrónico"
        />
      </div>
      <div class="sub">
        <h1>
          Recibirás en tu bandeja las instrucciones<br />
          para completar este proceso.
        </h1>
      </div>

      <div class="popupcontrols">
        <span id="popupclose">
          <div class="cerrar">
            <button @click="recuperarCorreo()" class="cerrar">
              <App-button :ancho="true" buttonText="Enviar" />
            </button>
            <button class="cerrar">
              <App-button :ancho="true" buttonText="Cerrar" />
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>


<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .login {
    margin-left: -55%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  input[type="correo"] {
    margin-top: 18%;

    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    margin-top: 4%;
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
    -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
    text-security: disc;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
  }
  .button {
    margin-left: 5%;
  }
  .registrate {
    margin-top: 10%;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
  }
  .recuperar {
    margin-top: 10%;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 35%;
    background: #fff;
    //width: 320px;
    //height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -5%;
  }
  input[type="correo2"] {
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 280px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .sub {
    font-family: "Montserrat";
    font-size: 12px;
    margin-top: 4%;
    margin-left: 10%;
    text-align: left;
    line-height: 1.6;
  }
  .cerrar {
    margin-top: -5%;
  }
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .login {
    margin-left: -36%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: bold;
  }
  .alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  input[type="correo"] {
    margin-top: 8%;
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    margin-top: 4%;
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
     -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
  .button {
    margin-left: 0%;
  }
  .registrate {
    margin-top: 5%;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
  }
  .recuperar {
    margin-top: 6%;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 45%;
    background: #fff;
    //width: 320px;
    //height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -5%;
  }
  input[type="correo2"] {
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 280px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .sub {
    font-family: "Montserrat";
    font-size: 12px;
    margin-top: 4%;
    margin-left: 10%;
    text-align: left;
    line-height: 1.6;
  }
  .cerrar {
    margin-top: -5%;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .login {
    margin-left: -24%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  input[type="correo"] {
    margin-top: 6%;
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    margin-top: 4%;

    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 40px;
    width: 320px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
     -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
  }
  .button {
    margin-left: -1%;
  }
  .registrate {
    margin-top: 3%;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
  }
  .recuperar {
    margin-top: 6%;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 45%;
    background: #fff;
    //width: 320px;
    //height: 220px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -5%;
  }
  input[type="correo2"] {
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 280px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .sub {
    font-family: "Montserrat";
    font-size: 12px;
    margin-top: 4%;
    margin-left: 10%;
    text-align: left;
    line-height: 1.6;
  }
  .cerrar {
    margin-top: -5%;
  }
}
@media screen and (min-width: 1201px) {
  .login {
    margin-left: -14%;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
  }
  .alert {
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  input[type="correo"] {
    margin-top: 4%;
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 50px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  input[type="contraseña"] {
    margin-top: 2%;

    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 50px;
    width: 390px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
     -webkit-text-security: disc; /* Para navegadores WebKit (Chrome, Safari) */
  text-security: disc;
  }

  input[placeholder] {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
  }
  .button {
    margin-top: 2%;
    margin-left: -1%;
  }
  .registrate {
    margin-top: 3%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 500;
  }
  .recuperar {
    margin-top: 6%;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 500;
  }
  #overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #999;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  #popup {
    display: none;
    position: absolute;
    top: 35%;
    left: 45%;
    background: #fff;
    //width: 420px;
    //height: 420px;
    margin-left: -100px; /*Half the value of width to center div*/
    margin-top: -100px; /*Half the value of height to center div*/
    z-index: 200;
  }
  #popupclose {
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  .popupcontent {
    padding: 10px;
  }
  .titulo {
    font-family: "Montserrat";
    font-size: 14px;
    margin-left: -5%;
  }
  input[type="correo2"] {
    margin-left: 8%;
    margin-right: 9%;
    text-indent: 20px;
    box-shadow: -1px 2px 1px 1px rgba(208, 215, 230, 255);
    height: 35px;
    width: 280px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 5px;
  }
  .sub {
    font-family: "Montserrat";
    font-size: 12px;
    margin-top: 4%;
    margin-left: 10%;
    text-align: left;
    line-height: 1.6;
  }
  .cerrar {
    margin-top: -5%;
  }
}
</style>


<script>
import axios from "axios";
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";

export default {
  name: "login",
  components: {
    Back,
    AppButton,
  },
  data: function () {
    return {
      recuperar: "",
      email: "",
      password: "",
      error: false,
      loading: false,
      error_msg: "",
      redirect_url: "https://ventas.apoloplatinum.com.mx/recuperar",
      valido: false,
    };
  },
  methods: {
    iniciar() {
      // Initialize Variables
      var closePopup = document.getElementById("popupclose");
      var overlay = document.getElementById("overlay");
      var popup = document.getElementById("popup");
      var button = document.getElementById("button");
      // Close Popup Event
      closePopup.onclick = function () {
        overlay.style.display = "none";
        popup.style.display = "none";
      };
      // Show Overlay and Popup
      button.onclick = function () {
        overlay.style.display = "block";
        popup.style.display = "block";
      };
    },
    async login() {
      const json = {
        data: {
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };
      this.loading = !false;
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
      };
      axios
        .post("/login", json, config)
        .then((data) => {
          if (data.status == "200") {
            setTimeout(() => {
              this.loading = true;
              this.error = false;
            }, 200),
              localStorage.setItem("access_token", data.data.access_token);
              this.$emit("login_realizado", true);
              this.$router.push("/cuenta");
          }
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
          }, 200),
          //this.error = true;
          //this.valido = false;
          //this.error_msg = error.response.data.errors[0].detail;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.errors[0].detail,
            duration: -1
          });
        });
      this.loading = !false;
    },
    async recuperarCorreo() {
      let json = {
        data: {
          attributes: {
            redirect_url: this.redirect_url,
            email: this.recuperar,
          },
        },
      };
      let config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      this.loading = !false;
      axios
        .post("/password-forgot", json, config)
        .then((response) => {
          //console.log(response);
          setTimeout(() => {
            this.loading = false;
          }, 200),
          //this.valido = true;
          //this.error = false;
          //this.error_msg = response.data.message;
          this.$notify({
            group: "apolo",
            type: "success",
            title: "Notificación Apolo Platinum",
            text: response.data.message,
            duration: -1
          });
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
          }, 200),
          //this.error = true;
          this.valido = false;
          //this.error_msg = error.response.data.message;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.message,
            duration: -1
          });
        });
      this.loading = !false;
    },
  },
};
</script>
