<template>
  <div class="hello">
    <div>
      <h3>{{ count }}</h3>
      <div class="btn-wrapper">
        <button class="btn btn-red" @click="decrement">-</button>
        <button class="btn btn-green" @click="increment">+</button>
      </div>
    </div>
    <div>
      <h3>{{ count1 }}</h3>
      <div class="btn-wrapper">
        <button class="btn btn-red" @click="decrement1">-</button>
        <button class="btn btn-green" @click="increment1">+</button>
      </div>
    </div>
	 <div>
      <h3>{{ count2 }}</h3>
      <div class="btn-wrapper">
        <button class="btn btn-red" @click="decrement2">-</button>
        <button class="btn btn-green" @click="increment2">+</button>
		
      </div>
	  
    </div>
  </div>
</template>

<script>
export default {
  name: "hello",
  data() {
    return {
      count: 0,
      count1: 0,
      count2: 0,
	  total: this.count+this.count1+this.count2,
    };
  },
  methods: {
    decrement() {
      if (this.count) {
        this.count -= 1;
        this.saveCount();
      }
    },
    increment() {
      this.count += 1;
      this.saveCount();
    },
    decrement1() {
      if (this.count1) {
        this.count1 -= 1;
        this.saveCount();
      }
    },
    increment1() {
      this.count1 += 1;
      this.saveCount();
    },
    decrement2() {
      if (this.count2) {
        this.count2 -= 1;
        this.saveCount();
      }
    },
    increment2() {
      this.count2 += 1;
      this.saveCount();
    },
    saveCount() {
      this.total = this.count + this.count1 + this.count2
	  localStorage.setItem("total_pasajeros", this.total);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
h3 {
  margin-top: -10%;
  margin-left: 20%;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0;
}

a {
  color: #42b983;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 10%;
}

.btn {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 35px;
  color: white;
  font-size: 20px;
  margin-top: -13%;
}

.btn:hover {
  cursor: pointer;
}

.btn-red {
  background: rgb(7, 7, 7);
}

.btn-red:hover {
  background: rgba(139, 0, 0, 0.9);
}

.btn-green {
  background: rgb(6, 6, 6);
}

.btn-green:hover {
  background: rgba(0, 128, 0, 0.9);
}
</style>
