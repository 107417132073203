<template>
 
    <div class="row">
      <v-row>
        <div class="titulo2">
          <h1>Mis Datos de facturación</h1>
        </div>
        <div class="back">
          <router-link to="/busqueda">
            <button>
              <Back />
            </button>
          </router-link>
        </div>
      </v-row>
      <DatosFacturar />
      <div class="button">
        <button>
      <router-link to="guardar">
        <App-button :largo="true" buttonText="Guardar" />
      </router-link>
    </button>

      </div>
    </div>

</template>


<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.titulo2 {
  font-family: "Montserrat";
  font-size: 4.5vw;
  margin-right: 20%;
  margin-left: 6%;
}
.row {
  font-weight: 600;
  margin-left: 3%;
  margin-top: 1%;
}
.back {
  margin-top: -10%;
  margin-left: 80%;
}
.button{
  margin-left: 15%;
}
</style>

<script>
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import BoletoFactura from "@/components/BoletoFactura.vue";
import DatosFacturar from "@/components/DatosFacturacion.vue";

export default {
  name: "busqueda",
  
  components: {
    Back,
    AppButton,
    BoletoFactura,
    DatosFacturar
  },
};
</script>