<template>
 <div id="click" class="container">      
       <button :class="[ancho ? 'ancho' : 'largo']">{{ buttonText }}</button>
      
    </div>
</template>

<script>
import { RouterLink } from 'vue-router'
export default {
  props: {
    buttonText: {
      type: String,
      default: () => "Label",
    },
    largo: {
      type: Boolean,
      default: () => true,
    },
    ancho: {
      type: Boolean,
      default: () => false,
    }
    
  }
}

</script>




<style scoped lang="less">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.container {
    margin: 15px auto;
}
.largo{
  font-size: 10px;
  width: 80px;
  height: 29px;

    


}
.ancho {
  font-size: 10px;
  width: 75px;
  height: 29px;
}

.container button {
     font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    box-shadow: -1px 1px 1px 1px rgba(208,215,230,255);
    
    background-color: #27096b;
    color: rgb(255, 255, 255);
    border: 1px solid #27096b;
    border-radius: 4px;
    cursor: pointer;
    
}
.container button.selected {
    background-color: #f7fafc;
    border-color: #fff;
    
    
}


h1 {
  margin: 30px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #ffffff;
}
</style>