var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"course"},[(_vm.error)?_c('div',{staticClass:"alert alert-primary",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")]):_vm._e(),_c('div',{staticClass:"preview"}),_c('div',{staticClass:"info"},_vm._l((_vm.lista),function(item,index){return _c('div',{key:item.id2,staticClass:"hora1",attrs:{"value":[
          item.attributes.fecha_salida,
          item.attributes.precios.precio_en_linea,
          item.attributes.fecha_llegada,
        ]},on:{"click":function($event){_vm.lista[index] = item.attributes.fecha_salida.replace('-', 'a')}}},[_c('div',{staticClass:"progress-wrapper"},[_c('div',{staticClass:"progress"},[_c('span',{staticClass:"progress-text2"},[_vm._v(" "+_vm._s(_vm.abreviatura_vuelta)+" ")])]),_c('div',{staticClass:"slp"},[_c('span',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(_vm.abreviatura_ida)+" ")])])]),_c('v-row',[_c('div',{staticClass:"hora2"},[_c('h6',[_vm._v(" "+_vm._s(item.attributes.fecha_salida.substring(11, 16))+" "+_vm._s(item.attributes.fecha_llegada.substring(11, 13) > 12 ? "PM" : "AM")+" ")])]),_c('div',{staticClass:"hora1"},[_c('h6',[_vm._v(" "+_vm._s(item.attributes.fecha_llegada.substring(11, 16))+" "+_vm._s(item.attributes.fecha_llegada.substring(11, 13) > 12 ? "PM" : "AM")+" ")])]),_c('div',{staticClass:"select"},[_c('button',{on:{"click":function($event){_vm.seleccionarSubcorrida(item.attributes.subcorrida_id);
                _vm.seleccionarCorrida(item.attributes.corrida_id);
                _vm.seleccionarRuta(item.attributes.ruta_id);
                _vm.selecionarhora1(
                  item.attributes.fecha_salida.substring(11, 16)
                );
                _vm.selecionarhora2(
                item.attributes.fecha_llegada.substring(11, 16)
              );}}},[_c('App-button',{attrs:{"ancho":true,"buttonText":"Seleccionar"}})],1)])]),_c('div',{staticClass:"clases"},[_c('p',{staticClass:"p-trunc"},[_vm._v(" "+_vm._s(item.attributes.precios[0].nombre)+":   $"+_vm._s(item.attributes.precios[0].precio_en_linea)+",    "+_vm._s(item.attributes.disponibilidad.P)+" asientos disponibles ")]),_c('p',{staticClass:"p-trunc2"},[_vm._v(" "+_vm._s(item.attributes.precios[1].nombre)+":   $"+_vm._s(item.attributes.precios[1].precio_en_linea)+",    "+_vm._s(item.attributes.disponibilidad.E)+" asientos disponibles ")]),_c('p',{staticClass:"p-trunc3"},[_vm._v(" "+_vm._s(item.attributes.precios[2].nombre)+":   $"+_vm._s(item.attributes.precios[2].precio_en_linea)+",    "+_vm._s(item.attributes.disponibilidad.C)+" asientos disponibles ")])]),_c('hr')],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }