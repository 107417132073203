<template>
  <div class="switch-button" id="switch-button" @click="click(posicion_actual)" :class="[ancho ? 'ancho' : 'largo']" >
    <div class="switch-item" @click="click(posicion='alta')">{{ buton1 }}</div>
    <div class="switch-item" @click="click(posicion='baja')">{{ buton2 }}</div>
  </div>
</template>


<script>
import Cancel from "@/components/ButtonCancel.vue";
import Servicios from "@/components/Servicios.vue";
export default {
  props: {
    buton1: {
      type: String,
      default: () => "Sencillo",
    },
    buton2: {
      type: String,
      default: () => "Redondo",
    },
    largo: {
      type: Boolean,
      default: () => true,
    },
    ancho: {
      type: Boolean,
      default: () => false,
    }
  },
  data(){
    return {
      posicion_actual: posicion
    }
  },
  
  methods: {
    click(posicion) {
      this.posicion_actual = posicion;
      let switch_button = document.getElementById("switch-button");
      switch_button.addEventListener("click", function () {
        this.classList.toggle("switch-active");
      });
      localStorage.setItem("posicion", posicion);
      //console.log('cambia'+posicion)
      this.$emit('eventToogle');
    },
  },
  components: {
    Cancel,
    Servicios
  },
  data() {
    return { comp: "Cancel" }
  },
};
</script>

<style scoped lang="less">
@media screen and (max-width: 480px) {
.switch-button {
  margin-top: 20%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
}
@media screen and (min-width: 481px)  and (max-width: 800px) {
  .switch-button {
  margin-top: 20%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
  
}
@media screen and (min-width: 801px)  and (max-width: 870px) {
  .switch-button {
  margin-top: 12%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
  
}
@media screen and (min-width: 871px)  and (max-width: 1030px) {
  .switch-button {
  margin-top: 12%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
  
}
@media screen and (min-width: 1031px)  and (max-width: 1280px) {
  .switch-button {
  margin-top: 8%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
  
}
@media screen and (min-width: 1281px)  {
  .switch-button {
  margin-top: 6%;
  box-shadow: 1px 2px 3px;
  margin-left: 20%;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  
  background: #f8fafc;
  display: flex;

  align-items: center;
  border-radius: 0.7rem;
}
.switch-button:before {
  content: "";
  position: absolute;
  cursor: pointer;
  left: -50%;
  top: -100%;
  height: 300%;
  width: 100%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
  border-radius: 47%;
}
.switch-item {
  position: relative;
  font-size: 16px;
padding: 1.5rem;
  color: #aba6a9;
  font-weight: 500;
  cursor: pointer;
}

.switch-item:first-child {
  color: rgb(255, 255, 255);
}
.switch-button.switch-active .switch-item:first-child {
  font-size: 16px;
  color: #aba6a9;
}
.switch-button.switch-active .switch-item:last-child {
  color: #fff;
}

.switch-button.switch-active:before {
  left: 50%;
  background: linear-gradient(to top right, #0569a0, #0569a0);
}

.largo{
    height: 2.5rem;
  width: 220px;
    
}
.ancho {
     height: 2.7rem;
  width: 280px;
  padding: 5px;
  margin-left: 10%;
   
   
}
  
}
</style>