<template>
  <div>
    <div class="cuadro">
      <img v-if="tipo === 'asiento' && disponible === 1 && seleccionado === false && pantalla === false" src="/img/layout/disponible.png" class="centrado" @click="selectAsiento()" v-on:click.right="blockAsiento()" style="cursor: pointer;">
      <img v-if="tipo === 'asiento' && disponible === 1 && seleccionado === false && pantalla === true" src="/img/layout/asientotv.png" class="centrado" @click="selectAsiento()" v-on:click.right="blockAsiento()" style="cursor: pointer;">
      <img v-if="tipo === 'asiento' && disponible === 0 && seleccionado === false && ocupado != '4'" src="/img/layout/ocupado.png" class="centrado">
      <img v-if="tipo === 'asiento' && seleccionado === true" src="/img/layout/seleccionado.png" class="centrado">
      <img v-if="tipo === 'asiento' && ocupado === '4'" src="/img/layout/ocupado.png" class="centrado" v-on:click.right="blockAsiento()" style="cursor: pointer;">
      <div class="text-center">
        <span class="numeroAsiento" v-if="numero && disponible === 0 && seleccionado === false && ocupado != '4'">{{numero}}|{{categoria_inicial}}</span>
        <span class="numeroAsiento" v-if="numero && disponible === 1 && seleccionado === false" @click="selectAsiento()" v-on:click.right="blockAsiento()" style="cursor: pointer;">{{numero}}|{{categoria_inicial}}</span>
        <span class="numeroAsiento" v-if="numero && disponible === 0 && seleccionado === false && ocupado === '4'" v-on:click.right="blockAsiento()" style="cursor: pointer;">{{numero}}|{{categoria_inicial}}</span>
        <span class="numeroAsiento" v-if="numero && seleccionado === true" style="color: white;">{{numero}}|{{categoria_inicial}}</span>
      </div>

      <img v-if="banoTipo === 'Mixto'" src="/img/layout/mixto.png" class="centrado">
      <img v-if="banoTipo === 'Hombres'" src="/img/layout/hombres.png" class="centrado">
      <img v-if="banoTipo === 'Mujeres'" src="/img/layout/mujeres.png" class="centrado">

      <img v-if="tipo === 'escalera'" src="/img/layout/escalera.png" class="centrado">
      <img v-if="tipo === 'operador'" src="/img/layout/operador.png" class="centrado">
      <img v-if="tipo === 'television'" src="/img/layout/television.png" class="centrado">
      <img v-if="tipo === 'cafeteria'" src="/img/layout/cafeteria.png" class="centrado">
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {
   
  },

  props: {
    value: { required: false },
    venta: { required: false },
    addDetail: { required: false },
    getIdaOVuelta: { required: false },
    id: { required: false },
  },
  data() {
    return {
      seleccion: {},
      tipo: null,
      disponible: null,
      seleccionado: false,
      ocupado: '',
      numero: null,
      categoria: null,
      categoria_inicial: null,
      pantalla: false,
      precio_en_taquilla: null,
      precio_en_linea: null,
      motivo_bloqueo: null,
      bano: null,
      banoTipo: null,
      banos: [
        { nombre: 'Mixto'},
        { nombre: 'Hombres'},
        { nombre: 'Mujeres'}
      ],
    };
  },
  created() {
    if(!!this.value){
      if(this.value.tipo){
        this.tipo = this.value.tipo;
      }
      if(this.value.tipo === 'asiento'){
        this.disponible = this.value.disponible;
        this.numero = this.value.numero;
        this.categoria = this.value.categoria;
        const categoriaNombre = this.value.categoria.nombre;
        this.categoria_inicial = categoriaNombre.charAt(0);
        this.pantalla = this.value.pantalla;
        this.ocupado = this.value.motivo_ocupado;
        this.motivo_bloqueo = this.value.motivo_bloqueo;
        this.precio_en_taquilla = this.value.precio_en_taquilla;
        this.precio_en_linea = this.value.precio_en_linea;
      }
      this.banoTipo = this.value.bano;
      if(this.value.bano === 'Mixto'){
        this.bano = this.banos[0];
      }
      if(this.value.bano === 'Hombres'){
        this.bano = this.banos[1];
      }
      if(this.value.bano === 'Mujeres'){
        this.bano = this.banos[2];
      }
    }
  },
  mounted(){
    if(this.venta.subcorrida_id != null){
      window.Echo.channel(`Subcorrida.${this.venta.subcorrida_id}`).listen('AsientoInteractivoEvent',(asiento)=>{  
        if(Number(this.numero) === Number(asiento.asiento.asiento)){
          this.disponible = asiento.asiento.disponible;
          this.updateItem();
        }
      })
    }
  },
  methods: {
    updateItem(){
      const val = {
        'disponible': this.disponible, 
        'ocupado': this.ocupado, 
        'seleccionado': this.seleccionado, 
        'numero': this.numero, 
        'categoria': this.categoria, 
        'tipo': this.tipo,
        'motivo_bloqueo': this.motivo_bloqueo,
        'motivo_ocupado': this.motivo_ocupado,
        'precio_en_linea': this.precio_en_linea,
        'precio_en_taquilla': this.precio_en_taquilla,
        'id': this.id,
      };
      this.$emit('input', val);
    },

    async resetAsiento(){
      let data = {
        data: {
          token_id: localStorage.getItem("token_id"),
          corrida_id: this.venta.corrida_id,
          subcorrida_id: this.venta.subcorrida_id,
          numero_asiento: this.numero,
          tipo: 'deseleccion'
        },
      };
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      };
      let me = this;
      axios.post("/seleccionarAsiento", data, options).then(function (response){
        me.value.id = me.id;
        me.disponible = 1;
        me.seleccionado = false;
        me.updateItem()
      }).catch(function (error){
        if (error.response.data.message) {
          me.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.message,
            duration: -1
          });
        }
      });
    },

    async selectAsiento(){
      let data = {
        data: {
          token_id: localStorage.getItem("token_id"),
          corrida_id: this.venta.corrida_id,
          subcorrida_id: this.venta.subcorrida_id,
          numero_asiento: this.value.numero,
          tipo: 'seleccion'
        },
      };
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      };
      let me = this;
      axios.post("/seleccionarAsiento", data, options).then(function(response) {
        me.seleccionado = true;
        me.value.id = me.id;
        me.updateItem();
        me.addDetail(me.value);
        }).catch(function (error){
          if (error.response.data.message) {
            if(error.response.data.message === 'SQLSTATE[23503]:Tu sesion caduco'){
              me.$notify({
                group: "apolo",
                type: "error",
                title: "Notificación Apolo Platinum",
                text: "Tu sesión caducó",
                duration: -1
              });
              me.$router.push("/");
            }
          }
        });
      },
  },
  watch: {
    value(newValue) {
      if(!!newValue){
        //this.tipo = newValue.tipo;
      } 
    },
  },
};
</script>
<style>
@media screen and (min-width: 200px) and (max-width: 599px)  {
    .cuadro{
      margin-left: -20%;
      border-style: none !important;
      height: 45px;
      width: 45px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .cuadro:hover{
      background-color: none !important;
      cursor: auto;
    }
    .centrado{
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 45px;
      width: 45px;
    }
    .numeroAsiento{
      position:relative;
      bottom: 20px;
      left: 13px;
      font-size: 12px;
    }
    .cuadro img{
      margin-left: -20%;
      transform: rotate(90deg);
      background-color: none !important;
    }
}

@media screen and (min-width: 600px)  {
    .cuadro{
      border-style: none !important;
      height: 65px;
      width: 65px;
      background-color: ffffff;
    }
    .cuadro:hover{
      background-color: none !important;
      cursor: auto;
    }
    .centrado{
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 55px;
      width: 55px;
      background-color: none !important;
    }
    .numeroAsiento{
      position:relative;
      bottom: 15px !important;
      left: 27px;
      font-size: 12px;
    }
    .cuadro img{
      transform: rotate(90deg);
      background-color: none !important;
    }
}
</style>