<template>
  <div>
    <div class="titulo">
      <h1>Hola</h1>
    </div>

    <div class="nombre">
      <h1>{{ nombre }}</h1>
    </div>
    <div class="sub">
      <Subtitulo3 subTitulo3="Es bueno tenerte de vuelta" />
    </div>
    <div class="container2">
      <div class="detalles">
        <h1>Detalles del perfil</h1>
      </div>
      <div class="subtitulo">
        <p align="left">
          Revisa y configura tu perfil, ajusta <br />
          tus datos para viajar más facíl y rápido
        </p>
      </div>
      <div class="back">
        <router-link to="/detalles">
          <button>
            <Back />
          </button>
        </router-link>
      </div>
    </div>
    <div class="container3">
      <div class="boletos">
        <h1>Mis boletos</h1>
      </div>
      <div class="subtitulo2">
        <p align="left">
          Revisa tus boletos, tus últimos viajes<br />
          y tus próximos viajes
        </p>
      </div>
      <div class="back2">
        <router-link to="/boletos">
          <button>
            <Back />
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 520px) {
  .titulo {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-right: 50%;
  }
  .nombre {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    margin-right: 42%;
    margin-top: -2%;
  }
  .sub {
    font-size: 14px;
    margin-right: 12%;
    margin-top: -4%;
  }
  .container2 {
    margin-top: 2%;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    width: 14em;
    height: 170px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .detalles {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 22px;
      font-weight: 600;
      margin-right: 15%;
    }
    .subtitulo {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 400;
      margin-left: 13%;
      line-height: normal;
    }
    .back {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
  .container3 {
    margin-top: 10%;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    width: 14em;
    height: 170px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .boletos {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 22px;
      font-weight: 600;
      margin-right: 33%;
    }
    .subtitulo2 {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 400;
      margin-left: 15%;
      line-height: normal;
    }
    .back2 {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
}
@media screen and (min-width: 521px) and (max-width: 767px) {
  .titulo {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 22px;
    margin-right: 32%;
  }
  .nombre {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 26px;
    margin-right: 28%;
    margin-top: -1%;
  }
  .sub {
    font-size: 18px;
    margin-right: 9%;
    margin-top: -2%;
  }
  .container2 {
    margin-top: 2%;
    margin-left: 20%;
    border-radius: 10px;
    width: 16em;
    height: 200px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .detalles {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 600;
      margin-right: 15%;
    }
    .subtitulo {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 400;
      margin-left: 13%;
      line-height: normal;
    }
    .back {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
  .container3 {
    margin-top: 8%;
    margin-left: 20%;
    border-radius: 10px;
    width: 16em;
    height: 200px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .boletos {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 600;
      margin-right: 33%;
    }
    .subtitulo2 {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 400;
      margin-left: 15%;
      line-height: normal;
    }
    .back2 {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .titulo {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 26px;
    margin-right: 12%;
  }
  .nombre {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 32px;
    margin-right: 10%;
    margin-top: 0%;
  }
  .sub {
    font-size: 20px;
    margin-right: -1%;
    margin-top: 0%;
  }
  .container2 {
    margin-top: 2%;
    margin-left: 30%;
    border-radius: 10px;
    width: 18em;
    height: 220px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .detalles {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 26px;
      font-weight: 600;
      margin-right: 15%;
    }
    .subtitulo {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 400;
      margin-left: 13%;
      line-height: normal;
    }
    .back {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
  .container3 {
    margin-top: 8%;
    margin-left: 30%;
    border-radius: 10px;
    width: 18em;
    height: 220px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .boletos {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 26px;
      font-weight: 600;
      margin-right: 33%;
    }
    .subtitulo2 {
      padding-top: 5px;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 400;
      margin-left: 15%;
      line-height: normal;
    }
    .back2 {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
}
@media screen and (min-width: 1200px) {
  .titulo {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 28px;
    margin-right: 12%;
  }
  .nombre {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 34px;
    margin-right: 10%;
    margin-top: 0%;
  }
  .sub {
    font-size: 22px;
    margin-right: -1%;
    margin-top: 0%;
  }
  .container2 {
    margin-top: 2%;
    margin-left: 35%;
    border-radius: 10px;
    width: 20em;
    height: 220px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .detalles {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 28px;
      font-weight: 600;
      margin-right: 15%;
    }
    .subtitulo {
      padding-top: 20px;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 400;
      margin-left: 13%;
      line-height: normal;
    }
    .back {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
  .container3 {
    margin-top: 5%;
    margin-left: 35%;
    border-radius: 10px;
    width: 20em;
    height: 220px;
    background-color: rgba(248, 250, 252, 255);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2);

    .boletos {
      padding-top: 15px;
      font-family: "Montserrat";
      font-size: 28px;
      font-weight: 600;
      margin-right: 33%;
    }
    .subtitulo2 {
      padding-top: 20px;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 400;
      margin-left: 15%;
      line-height: normal;
    }
    .back2 {
      margin-top: 10%;
      margin-left: 79%;
    }
  }
}
</style>

<script>
import Back from "@/components/Backleft.vue";
import axios from "axios";
import AppButton from "@/components/ButtonPrimary.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";

export default {
  name: "login",
  data() {
    return {
      nombre: "",
    };
  },
  async mounted() {
    if (localStorage.getItem("access_token")) {
      Promise.all([this.obtenername()]).then(
        function () {
          
        }
      );
    } else {
      this.$router.push("/login");
    }
  },
  components: {
    Back,
    AppButton,
    Subtitulo3,
  },
  methods: {
    async obtenername() {
      const token3 = localStorage.getItem("access_token");
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token3,
          access_token: token3,
        },
      };
      axios
        .get("/obtenerMiPerfil", config)
        .then((response) => {
          const name = response.data.data.attributes.name;
          const last_name = response.data.data.attributes.last_name;
          localStorage.setItem("name", name);
          localStorage.setItem("correo", response.data.data.attributes.email);
          this.nombre = localStorage.getItem("name");
          localStorage.setItem("last_name", last_name);
        })
        .catch((error) => {
          //this.error = true;
          //this.error_msg = error.response.data.message;
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.message,
            duration: -1
          });
        });
    },
   
  },
};
</script>