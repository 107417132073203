<template>
  <div>
    <v-row>
      <div class="cuenta">
        <h1>Buscar Boleto a Facturar</h1>
      </div>
      <div class="back">
        <router-link to="/">
        <Back />
        </router-link>
      </div>
    </v-row>
    <div class="sub">
      <br>
    </div>
    <div>
      <input v-model="boleto.id" type="boleto" placeholder="# Boleto" />
    </div>
    <div>
      <input v-model="boleto.clave_facturacion"  type="factura" placeholder="Clave de facturación" />
    </div>
  
    <div>
      <button @click="getBoleto()" id="button">
        <App-button :largo="true" buttonText="Buscar" />
      </button>
    </div>


  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
.cuenta {
  margin-top: 10%;
  margin-left: 15%;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
}
.back {
  margin-left: 10%;
  margin-top: 8%;
}
.sub {
  margin-left: -40%;
  margin-top: -5%;
  font-size: 14px;
}
input[type="boleto"] {
  margin-top: -10%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 35px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}
input[type="factura"] {
  margin-top: 4%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 35px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}
input[placeholder] {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
}
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .cuenta {
  margin-top: 10%;
  margin-left: 30%;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: bold;
}
.back {
  margin-left: 5%;
  margin-top: 8%;
}
.sub {
  margin-left: -17%;
  margin-top: -2%;
  font-size: 16px;
}
input[type="boleto"] {
  margin-top: -10%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 40px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}
input[type="factura"] {
  margin-top: 4%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 40px;
  width: 320px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}

}
@media screen and (min-width: 767px) and (max-width: 1200px) {
   .cuenta {
  margin-top: 5%;
  margin-left: 35%;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: bold;
}
.back {
  margin-left: 7%;
  margin-top: 4%;
}
.sub {
  margin-left: -5%;
  margin-top: 1%;
  font-size: 18px;
}
input[type="boleto"] {
  margin-top: -10%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}
input[type="factura"] {
  margin-top: 3%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}

}
@media screen and (min-width: 1201px)  {
   .cuenta {
  margin-top: 5%;
  margin-left: 41%;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: bold;
}
.back {
  margin-left: 7%;
  margin-top: 4%;
}
.sub {
  margin-left: -5%;
  margin-top: 1%;
  font-size: 16px;
}
input[type="boleto"] {
  margin-top: -10%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}
input[type="factura"] {
  margin-top: 3%;

  margin-left: 1%;
  text-indent: 20px;
  box-shadow: -1px 2px 1px 1px rgba(208,215,230,255);
  height: 45px;
  width: 390px;
  background-color: rgba(248, 250, 252, 255);
  border: 2px solid rgba(248, 250, 252, 255);
  border-radius: 5px;
}

input[placeholder] {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}

}


</style>

<script>
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      token_access: null,
      boleto: {
        type: "boleto",
        id: '',
        clave_facturacion: ''
      },
      boleto_obtenido: null
    };
  },
  mounted(){
    if(localStorage.getItem('access_token'))
    {
      this.$router.push("/cuenta");
    }
  },
  components: {
    Back,
    AppButton,
    Subtitulo3,
  },
  methods: {
    async getBoleto() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      };

      axios
        .get(`/facturacion/buscarBoleto?id=${this.boleto.id}&clave_facturacion=${this.boleto.clave_facturacion}`, options)
        .then((response) => {
          this.boleto_obtenido = response.data;
          this.$router.push({
            name: "boleto-factura",
            params: { boleto: this.boleto_obtenido },
          });
          
        })
        .catch((error) => {
          if(error.response.data.errors){
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors[0].detail,
              duration: -1
            });
          }
          if(error.response.data.message){
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.message,
              duration: -1
            });
          }
        });
    },
  }, 
};
</script>
