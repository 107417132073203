<template>
  <div>
    <v-row>
      <div class="cuenta">
        <h1>Mis datos de facturación</h1>
      </div>
      <div class="back">
        <router-link to="/detalles">
          <Back />
        </router-link>
      </div>
    </v-row>
    <DatosFacturar />
  </div>
</template>


<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (max-width: 480px) {
.cuenta {
  margin-top: 10%;
  margin-left: 15%;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}
.back {
  margin-left: 10%;
  margin-top: 8%;
}
.check{
  margin-top: 10%;
  margin-left: 5%;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;

}

}
@media screen and (min-width: 481px) and (max-width: 766px) {
  .cuenta {
  margin-top: 10%;
  margin-left: 26%;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: bold;
}
.back {
  margin-left: 10%;
  margin-top: 8%;
}
.sub {
  margin-left: -17%;
  margin-top: -2%;
  font-size: 14px;
}
.check{
  margin-top: 6%;
  margin-left: 5%;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;

}


}
@media screen and (min-width: 767px) and (max-width: 1200px) {
   .cuenta {
  margin-top: 5%;
  margin-left: 30%;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: bold;
}
.back {
  margin-left: 6%;
  margin-top: 4%;
}
.check{
  margin-top: 6%;
  margin-left: 5%;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;

}
}
@media screen and (min-width: 1201px)  {
   .cuenta {
  margin-top: 5%;
  margin-left: 41%;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: bold;
}
.back {
  margin-left: 7%;
  margin-top: 4%;
}
.check{
  margin-top: 6%;
  margin-left: 5%;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: bold;

}


}
</style>

<script>
import Back from "@/components/Back.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import BoletoFactura from "@/components/BoletoFactura.vue";
import DatosFacturar from "@/components/DatosFacturacion.vue";

export default {
  name: "busqueda",
  components: {
    Back,
    AppButton,
    BoletoFactura,
    DatosFacturar,
  },
};
</script>