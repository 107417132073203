<template>
  <div
    class="bg-sky-700 text-red-100 py-2.5 px-6 shadow md:flex justify-between items-center"
  >
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
    />

    <div class="flex items-center cursor-pointer" >
      <span class="text-green-500 text-x1 mr-1 "> </span>
      <div id="appp">
        <a href="/"><img src="/images/logo.png" width="190"></a>
      </div>
    </div>
    <span
      @click="Menuopen()"
      class="absolute right-4 top-0.5 cursor-poinet text-4xl"
    >
      <i :class="[open ? 'bi bi-x' : 'bi bi-filter-right']"></i>
    </span>
    <ul
      class="
        md:flex md:items-center md:px-02
        px-10
        md:pb-2
        pb-2        
        absolute
        md:w-auto
        w-full
        bg-sky-700
        duration-700
        ease-in
      "
      :class="[open ? 'relative' : 'left-[-100%]']"
    >
      <li class="md:mx-4" v-show="!login">
        <a href="/cuenta" class="text-x1  hover:text-red-500">Mi cuenta</a>
      </li>
      <li class="md:mx-4" v-show="login">
        <a href="/login" class="text-x1 hover:text-red-500">Iniciar Sesión</a>
      </li>
      <li class="md:mx-4"  v-show="login">
        <a href="/registrate" class="text-x1 hover:text-red-500">Regístrate</a>
      </li>
       <li class="md:mx-4">
        <a href="/" class="text-x1 hover:text-red-500">Comprar Boletos</a>
      </li>
      <li class="md:mx-4"  v-show="login">
        <a href="/busqueda" class="text-x1 hover:text-red-500">Facturar</a>
      </li>
       <li class="md:mx-4"  v-show="logout" style="cursor:pointer;"  @click="cerarSesion(); ">
        <a  class="text-x1  hover:text-red-500">Cerrar Sesión</a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

#appp {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;

  color: #ffffff;
  font-weight: bold;
}
</style>
<script>
import { ref } from "vue";
export default {
  props: {
    logueado: { required: false },
  },
  data: function () {
    return {
      logout: false,
      login: true,
    }
  },
   mounted(){
    const logueado = localStorage.getItem('access_token')
    if(logueado === 'null' || logueado === null) {
      this.logout = false;
      this.login = true;
    } else {
      this.logout = true;
      this.login = false;
      
    }
    }, 
    watch: {
      logueado: {
        handler: "checkLogueado",
        immediate: false,
        deep: true,
      },

    },

  
  setup() {
    var open = ref(false);
    function Menuopen() {
      const logueado = localStorage.getItem('access_token')
      /*
      if(logueado === null) {
        this.logout = true;
        this.login = false;
      } else {
        this.logout = false;
        this.login = true;
      }*/
      open.value = !open.value;
    }
    
    return { open, Menuopen };
  },
  methods: {
    checkLogueado(){
      if(this.logueado) {
        this.logout = true;
        this.login = false;
      } else {
        this.logout = false;
        this.login = true;
      }
    },
    cerarSesion() {
      localStorage.clear();
      if(localStorage.getItem('access_token')) {
        this.logout = true;
        this.login = false;
      } else {
        this.logout = false;
        this.login = true;
      }
      this.$router.push("/");
    },    
  }
};
</script>
<style src="./assets/tailwind.css"/>




